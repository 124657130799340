import {CollectionEvent as _ol_Collection$CollectionEvent} from '../../ol/Collection.js';
import $ol$Collection from '../../ol/Collection.js';
import {asImageLike as _ol_DataTile$asImageLike} from '../../ol/DataTile.js';
import {asArrayLike as _ol_DataTile$asArrayLike} from '../../ol/DataTile.js';
import {toArray as _ol_DataTile$toArray} from '../../ol/DataTile.js';
import $ol$DataTile from '../../ol/DataTile.js';
import $ol$Disposable from '../../ol/Disposable.js';
import $ol$Feature from '../../ol/Feature.js';
import {createStyleFunction as _ol_Feature$createStyleFunction} from '../../ol/Feature.js';
import {GeolocationError as _ol_Geolocation$GeolocationError} from '../../ol/Geolocation.js';
import $ol$Geolocation from '../../ol/Geolocation.js';
import $ol$Image from '../../ol/Image.js';
import {listenImage as _ol_Image$listenImage} from '../../ol/Image.js';
import {load as _ol_Image$load} from '../../ol/Image.js';
import {decodeFallback as _ol_Image$decodeFallback} from '../../ol/Image.js';
import {decode as _ol_Image$decode} from '../../ol/Image.js';
import $ol$ImageCanvas from '../../ol/ImageCanvas.js';
import $ol$ImageTile from '../../ol/ImageTile.js';
import $ol$Kinetic from '../../ol/Kinetic.js';
import $ol$Map from '../../ol/Map.js';
import $ol$MapBrowserEvent from '../../ol/MapBrowserEvent.js';
import $ol$MapBrowserEventHandler from '../../ol/MapBrowserEventHandler.js';
import $ol$MapEvent from '../../ol/MapEvent.js';
import {ObjectEvent as _ol_Object$ObjectEvent} from '../../ol/Object.js';
import $ol$Object from '../../ol/Object.js';
import $ol$Observable from '../../ol/Observable.js';
import {unByKey as _ol_Observable$unByKey} from '../../ol/Observable.js';
import $ol$Overlay from '../../ol/Overlay.js';
import $ol$Tile from '../../ol/Tile.js';
import $ol$TileCache from '../../ol/TileCache.js';
import $ol$TileQueue from '../../ol/TileQueue.js';
import {getTilePriority as _ol_TileQueue$getTilePriority} from '../../ol/TileQueue.js';
import $ol$TileRange from '../../ol/TileRange.js';
import {createOrUpdate as _ol_TileRange$createOrUpdate} from '../../ol/TileRange.js';
import $ol$VectorRenderTile from '../../ol/VectorRenderTile.js';
import $ol$VectorTile from '../../ol/VectorTile.js';
import $ol$View from '../../ol/View.js';
import {createCenterConstraint as _ol_View$createCenterConstraint} from '../../ol/View.js';
import {createResolutionConstraint as _ol_View$createResolutionConstraint} from '../../ol/View.js';
import {createRotationConstraint as _ol_View$createRotationConstraint} from '../../ol/View.js';
import {isNoopAnimation as _ol_View$isNoopAnimation} from '../../ol/View.js';
import {binarySearch as _ol_array$binarySearch} from '../../ol/array.js';
import {ascending as _ol_array$ascending} from '../../ol/array.js';
import {descending as _ol_array$descending} from '../../ol/array.js';
import {linearFindNearest as _ol_array$linearFindNearest} from '../../ol/array.js';
import {reverseSubArray as _ol_array$reverseSubArray} from '../../ol/array.js';
import {extend as _ol_array$extend} from '../../ol/array.js';
import {remove as _ol_array$remove} from '../../ol/array.js';
import {equals as _ol_array$equals} from '../../ol/array.js';
import {stableSort as _ol_array$stableSort} from '../../ol/array.js';
import {isSorted as _ol_array$isSorted} from '../../ol/array.js';
import {assert as _ol_asserts$assert} from '../../ol/asserts.js';
import {createExtent as _ol_centerconstraint$createExtent} from '../../ol/centerconstraint.js';
import {none as _ol_centerconstraint$none} from '../../ol/centerconstraint.js';
import {asString as _ol_color$asString} from '../../ol/color.js';
import {withAlpha as _ol_color$withAlpha} from '../../ol/color.js';
import {rgbaToLcha as _ol_color$rgbaToLcha} from '../../ol/color.js';
import {lchaToRgba as _ol_color$lchaToRgba} from '../../ol/color.js';
import {fromString as _ol_color$fromString} from '../../ol/color.js';
import {asArray as _ol_color$asArray} from '../../ol/color.js';
import {normalize as _ol_color$normalize} from '../../ol/color.js';
import {toString as _ol_color$toString} from '../../ol/color.js';
import {isStringColor as _ol_color$isStringColor} from '../../ol/color.js';
import {asColorLike as _ol_colorlike$asColorLike} from '../../ol/colorlike.js';
import {setLevel as _ol_console$setLevel} from '../../ol/console.js';
import {log as _ol_console$log} from '../../ol/console.js';
import {warn as _ol_console$warn} from '../../ol/console.js';
import {error as _ol_console$error} from '../../ol/console.js';
import {add as _ol_coordinate$add} from '../../ol/coordinate.js';
import {closestOnCircle as _ol_coordinate$closestOnCircle} from '../../ol/coordinate.js';
import {closestOnSegment as _ol_coordinate$closestOnSegment} from '../../ol/coordinate.js';
import {createStringXY as _ol_coordinate$createStringXY} from '../../ol/coordinate.js';
import {degreesToStringHDMS as _ol_coordinate$degreesToStringHDMS} from '../../ol/coordinate.js';
import {format as _ol_coordinate$format} from '../../ol/coordinate.js';
import {equals as _ol_coordinate$equals} from '../../ol/coordinate.js';
import {rotate as _ol_coordinate$rotate} from '../../ol/coordinate.js';
import {scale as _ol_coordinate$scale} from '../../ol/coordinate.js';
import {squaredDistance as _ol_coordinate$squaredDistance} from '../../ol/coordinate.js';
import {distance as _ol_coordinate$distance} from '../../ol/coordinate.js';
import {squaredDistanceToSegment as _ol_coordinate$squaredDistanceToSegment} from '../../ol/coordinate.js';
import {toStringHDMS as _ol_coordinate$toStringHDMS} from '../../ol/coordinate.js';
import {toStringXY as _ol_coordinate$toStringXY} from '../../ol/coordinate.js';
import {wrapX as _ol_coordinate$wrapX} from '../../ol/coordinate.js';
import {getWorldsAway as _ol_coordinate$getWorldsAway} from '../../ol/coordinate.js';
import {CLASS_HIDDEN as _ol_css$CLASS_HIDDEN} from '../../ol/css.js';
import {CLASS_SELECTABLE as _ol_css$CLASS_SELECTABLE} from '../../ol/css.js';
import {CLASS_UNSELECTABLE as _ol_css$CLASS_UNSELECTABLE} from '../../ol/css.js';
import {CLASS_UNSUPPORTED as _ol_css$CLASS_UNSUPPORTED} from '../../ol/css.js';
import {CLASS_CONTROL as _ol_css$CLASS_CONTROL} from '../../ol/css.js';
import {CLASS_COLLAPSED as _ol_css$CLASS_COLLAPSED} from '../../ol/css.js';
import {getFontParameters as _ol_css$getFontParameters} from '../../ol/css.js';
import {createCanvasContext2D as _ol_dom$createCanvasContext2D} from '../../ol/dom.js';
import {getSharedCanvasContext2D as _ol_dom$getSharedCanvasContext2D} from '../../ol/dom.js';
import {releaseCanvas as _ol_dom$releaseCanvas} from '../../ol/dom.js';
import {outerWidth as _ol_dom$outerWidth} from '../../ol/dom.js';
import {outerHeight as _ol_dom$outerHeight} from '../../ol/dom.js';
import {replaceNode as _ol_dom$replaceNode} from '../../ol/dom.js';
import {removeNode as _ol_dom$removeNode} from '../../ol/dom.js';
import {removeChildren as _ol_dom$removeChildren} from '../../ol/dom.js';
import {replaceChildren as _ol_dom$replaceChildren} from '../../ol/dom.js';
import {easeIn as _ol_easing$easeIn} from '../../ol/easing.js';
import {easeOut as _ol_easing$easeOut} from '../../ol/easing.js';
import {inAndOut as _ol_easing$inAndOut} from '../../ol/easing.js';
import {linear as _ol_easing$linear} from '../../ol/easing.js';
import {upAndDown as _ol_easing$upAndDown} from '../../ol/easing.js';
import {listen as _ol_events$listen} from '../../ol/events.js';
import {listenOnce as _ol_events$listenOnce} from '../../ol/events.js';
import {unlistenByKey as _ol_events$unlistenByKey} from '../../ol/events.js';
import {boundingExtent as _ol_extent$boundingExtent} from '../../ol/extent.js';
import {buffer as _ol_extent$buffer} from '../../ol/extent.js';
import {clone as _ol_extent$clone} from '../../ol/extent.js';
import {closestSquaredDistanceXY as _ol_extent$closestSquaredDistanceXY} from '../../ol/extent.js';
import {containsCoordinate as _ol_extent$containsCoordinate} from '../../ol/extent.js';
import {containsExtent as _ol_extent$containsExtent} from '../../ol/extent.js';
import {containsXY as _ol_extent$containsXY} from '../../ol/extent.js';
import {coordinateRelationship as _ol_extent$coordinateRelationship} from '../../ol/extent.js';
import {createEmpty as _ol_extent$createEmpty} from '../../ol/extent.js';
import {createOrUpdate as _ol_extent$createOrUpdate} from '../../ol/extent.js';
import {createOrUpdateEmpty as _ol_extent$createOrUpdateEmpty} from '../../ol/extent.js';
import {createOrUpdateFromCoordinate as _ol_extent$createOrUpdateFromCoordinate} from '../../ol/extent.js';
import {createOrUpdateFromCoordinates as _ol_extent$createOrUpdateFromCoordinates} from '../../ol/extent.js';
import {createOrUpdateFromFlatCoordinates as _ol_extent$createOrUpdateFromFlatCoordinates} from '../../ol/extent.js';
import {createOrUpdateFromRings as _ol_extent$createOrUpdateFromRings} from '../../ol/extent.js';
import {equals as _ol_extent$equals} from '../../ol/extent.js';
import {approximatelyEquals as _ol_extent$approximatelyEquals} from '../../ol/extent.js';
import {extend as _ol_extent$extend} from '../../ol/extent.js';
import {extendCoordinate as _ol_extent$extendCoordinate} from '../../ol/extent.js';
import {extendCoordinates as _ol_extent$extendCoordinates} from '../../ol/extent.js';
import {extendFlatCoordinates as _ol_extent$extendFlatCoordinates} from '../../ol/extent.js';
import {extendRings as _ol_extent$extendRings} from '../../ol/extent.js';
import {extendXY as _ol_extent$extendXY} from '../../ol/extent.js';
import {forEachCorner as _ol_extent$forEachCorner} from '../../ol/extent.js';
import {getArea as _ol_extent$getArea} from '../../ol/extent.js';
import {getBottomLeft as _ol_extent$getBottomLeft} from '../../ol/extent.js';
import {getBottomRight as _ol_extent$getBottomRight} from '../../ol/extent.js';
import {getCenter as _ol_extent$getCenter} from '../../ol/extent.js';
import {getCorner as _ol_extent$getCorner} from '../../ol/extent.js';
import {getEnlargedArea as _ol_extent$getEnlargedArea} from '../../ol/extent.js';
import {getForViewAndSize as _ol_extent$getForViewAndSize} from '../../ol/extent.js';
import {getRotatedViewport as _ol_extent$getRotatedViewport} from '../../ol/extent.js';
import {getHeight as _ol_extent$getHeight} from '../../ol/extent.js';
import {getIntersectionArea as _ol_extent$getIntersectionArea} from '../../ol/extent.js';
import {getIntersection as _ol_extent$getIntersection} from '../../ol/extent.js';
import {getMargin as _ol_extent$getMargin} from '../../ol/extent.js';
import {getSize as _ol_extent$getSize} from '../../ol/extent.js';
import {getTopLeft as _ol_extent$getTopLeft} from '../../ol/extent.js';
import {getTopRight as _ol_extent$getTopRight} from '../../ol/extent.js';
import {getWidth as _ol_extent$getWidth} from '../../ol/extent.js';
import {intersects as _ol_extent$intersects} from '../../ol/extent.js';
import {isEmpty as _ol_extent$isEmpty} from '../../ol/extent.js';
import {returnOrUpdate as _ol_extent$returnOrUpdate} from '../../ol/extent.js';
import {scaleFromCenter as _ol_extent$scaleFromCenter} from '../../ol/extent.js';
import {intersectsSegment as _ol_extent$intersectsSegment} from '../../ol/extent.js';
import {applyTransform as _ol_extent$applyTransform} from '../../ol/extent.js';
import {wrapX as _ol_extent$wrapX} from '../../ol/extent.js';
import {wrapAndSliceX as _ol_extent$wrapAndSliceX} from '../../ol/extent.js';
import {loadFeaturesXhr as _ol_featureloader$loadFeaturesXhr} from '../../ol/featureloader.js';
import {xhr as _ol_featureloader$xhr} from '../../ol/featureloader.js';
import {setWithCredentials as _ol_featureloader$setWithCredentials} from '../../ol/featureloader.js';
import {TRUE as _ol_functions$TRUE} from '../../ol/functions.js';
import {FALSE as _ol_functions$FALSE} from '../../ol/functions.js';
import {VOID as _ol_functions$VOID} from '../../ol/functions.js';
import {memoizeOne as _ol_functions$memoizeOne} from '../../ol/functions.js';
import {toPromise as _ol_functions$toPromise} from '../../ol/functions.js';
import {FIREFOX as _ol_has$FIREFOX} from '../../ol/has.js';
import {SAFARI as _ol_has$SAFARI} from '../../ol/has.js';
import {SAFARI_BUG_237906 as _ol_has$SAFARI_BUG_237906} from '../../ol/has.js';
import {WEBKIT as _ol_has$WEBKIT} from '../../ol/has.js';
import {MAC as _ol_has$MAC} from '../../ol/has.js';
import {DEVICE_PIXEL_RATIO as _ol_has$DEVICE_PIXEL_RATIO} from '../../ol/has.js';
import {WORKER_OFFSCREEN_CANVAS as _ol_has$WORKER_OFFSCREEN_CANVAS} from '../../ol/has.js';
import {IMAGE_DECODE as _ol_has$IMAGE_DECODE} from '../../ol/has.js';
import {CREATE_IMAGE_BITMAP as _ol_has$CREATE_IMAGE_BITMAP} from '../../ol/has.js';
import {PASSIVE_EVENT_LISTENERS as _ol_has$PASSIVE_EVENT_LISTENERS} from '../../ol/has.js';
import {all as _ol_loadingstrategy$all} from '../../ol/loadingstrategy.js';
import {bbox as _ol_loadingstrategy$bbox} from '../../ol/loadingstrategy.js';
import {tile as _ol_loadingstrategy$tile} from '../../ol/loadingstrategy.js';
import {clamp as _ol_math$clamp} from '../../ol/math.js';
import {squaredSegmentDistance as _ol_math$squaredSegmentDistance} from '../../ol/math.js';
import {squaredDistance as _ol_math$squaredDistance} from '../../ol/math.js';
import {solveLinearSystem as _ol_math$solveLinearSystem} from '../../ol/math.js';
import {toDegrees as _ol_math$toDegrees} from '../../ol/math.js';
import {toRadians as _ol_math$toRadians} from '../../ol/math.js';
import {modulo as _ol_math$modulo} from '../../ol/math.js';
import {lerp as _ol_math$lerp} from '../../ol/math.js';
import {toFixed as _ol_math$toFixed} from '../../ol/math.js';
import {round as _ol_math$round} from '../../ol/math.js';
import {floor as _ol_math$floor} from '../../ol/math.js';
import {ceil as _ol_math$ceil} from '../../ol/math.js';
import {jsonp as _ol_net$jsonp} from '../../ol/net.js';
import {ResponseError as _ol_net$ResponseError} from '../../ol/net.js';
import {ClientError as _ol_net$ClientError} from '../../ol/net.js';
import {getJSON as _ol_net$getJSON} from '../../ol/net.js';
import {resolveUrl as _ol_net$resolveUrl} from '../../ol/net.js';
import {overrideXHR as _ol_net$overrideXHR} from '../../ol/net.js';
import {restoreXHR as _ol_net$restoreXHR} from '../../ol/net.js';
import {clear as _ol_obj$clear} from '../../ol/obj.js';
import {isEmpty as _ol_obj$isEmpty} from '../../ol/obj.js';
import {disableCoordinateWarning as _ol_proj$disableCoordinateWarning} from '../../ol/proj.js';
import {cloneTransform as _ol_proj$cloneTransform} from '../../ol/proj.js';
import {identityTransform as _ol_proj$identityTransform} from '../../ol/proj.js';
import {addProjection as _ol_proj$addProjection} from '../../ol/proj.js';
import {addProjections as _ol_proj$addProjections} from '../../ol/proj.js';
import {get as _ol_proj$get} from '../../ol/proj.js';
import {getPointResolution as _ol_proj$getPointResolution} from '../../ol/proj.js';
import {addEquivalentProjections as _ol_proj$addEquivalentProjections} from '../../ol/proj.js';
import {addEquivalentTransforms as _ol_proj$addEquivalentTransforms} from '../../ol/proj.js';
import {clearAllProjections as _ol_proj$clearAllProjections} from '../../ol/proj.js';
import {createProjection as _ol_proj$createProjection} from '../../ol/proj.js';
import {createTransformFromCoordinateTransform as _ol_proj$createTransformFromCoordinateTransform} from '../../ol/proj.js';
import {addCoordinateTransforms as _ol_proj$addCoordinateTransforms} from '../../ol/proj.js';
import {fromLonLat as _ol_proj$fromLonLat} from '../../ol/proj.js';
import {toLonLat as _ol_proj$toLonLat} from '../../ol/proj.js';
import {equivalent as _ol_proj$equivalent} from '../../ol/proj.js';
import {getTransformFromProjections as _ol_proj$getTransformFromProjections} from '../../ol/proj.js';
import {getTransform as _ol_proj$getTransform} from '../../ol/proj.js';
import {transform as _ol_proj$transform} from '../../ol/proj.js';
import {transformExtent as _ol_proj$transformExtent} from '../../ol/proj.js';
import {transformWithProjections as _ol_proj$transformWithProjections} from '../../ol/proj.js';
import {setUserProjection as _ol_proj$setUserProjection} from '../../ol/proj.js';
import {clearUserProjection as _ol_proj$clearUserProjection} from '../../ol/proj.js';
import {getUserProjection as _ol_proj$getUserProjection} from '../../ol/proj.js';
import {useGeographic as _ol_proj$useGeographic} from '../../ol/proj.js';
import {toUserCoordinate as _ol_proj$toUserCoordinate} from '../../ol/proj.js';
import {fromUserCoordinate as _ol_proj$fromUserCoordinate} from '../../ol/proj.js';
import {toUserExtent as _ol_proj$toUserExtent} from '../../ol/proj.js';
import {fromUserExtent as _ol_proj$fromUserExtent} from '../../ol/proj.js';
import {toUserResolution as _ol_proj$toUserResolution} from '../../ol/proj.js';
import {fromUserResolution as _ol_proj$fromUserResolution} from '../../ol/proj.js';
import {createSafeCoordinateTransform as _ol_proj$createSafeCoordinateTransform} from '../../ol/proj.js';
import {addCommon as _ol_proj$addCommon} from '../../ol/proj.js';
import {toContext as _ol_render$toContext} from '../../ol/render.js';
import {getVectorContext as _ol_render$getVectorContext} from '../../ol/render.js';
import {getRenderPixel as _ol_render$getRenderPixel} from '../../ol/render.js';
import {canvasPool as _ol_reproj$canvasPool} from '../../ol/reproj.js';
import {calculateSourceResolution as _ol_reproj$calculateSourceResolution} from '../../ol/reproj.js';
import {calculateSourceExtentResolution as _ol_reproj$calculateSourceExtentResolution} from '../../ol/reproj.js';
import {render as _ol_reproj$render} from '../../ol/reproj.js';
import {fromResolutionLike as _ol_resolution$fromResolutionLike} from '../../ol/resolution.js';
import {createSnapToResolutions as _ol_resolutionconstraint$createSnapToResolutions} from '../../ol/resolutionconstraint.js';
import {createSnapToPower as _ol_resolutionconstraint$createSnapToPower} from '../../ol/resolutionconstraint.js';
import {createMinMaxResolution as _ol_resolutionconstraint$createMinMaxResolution} from '../../ol/resolutionconstraint.js';
import {disable as _ol_rotationconstraint$disable} from '../../ol/rotationconstraint.js';
import {none as _ol_rotationconstraint$none} from '../../ol/rotationconstraint.js';
import {createSnapToN as _ol_rotationconstraint$createSnapToN} from '../../ol/rotationconstraint.js';
import {createSnapToZero as _ol_rotationconstraint$createSnapToZero} from '../../ol/rotationconstraint.js';
import {buffer as _ol_size$buffer} from '../../ol/size.js';
import {hasArea as _ol_size$hasArea} from '../../ol/size.js';
import {scale as _ol_size$scale} from '../../ol/size.js';
import {toSize as _ol_size$toSize} from '../../ol/size.js';
import {sourcesFromTileGrid as _ol_source$sourcesFromTileGrid} from '../../ol/source.js';
import {DEFAULT_RADIUS as _ol_sphere$DEFAULT_RADIUS} from '../../ol/sphere.js';
import {getDistance as _ol_sphere$getDistance} from '../../ol/sphere.js';
import {getLength as _ol_sphere$getLength} from '../../ol/sphere.js';
import {getArea as _ol_sphere$getArea} from '../../ol/sphere.js';
import {offset as _ol_sphere$offset} from '../../ol/sphere.js';
import {padNumber as _ol_string$padNumber} from '../../ol/string.js';
import {compareVersions as _ol_string$compareVersions} from '../../ol/string.js';
import {createOrUpdate as _ol_tilecoord$createOrUpdate} from '../../ol/tilecoord.js';
import {getKeyZXY as _ol_tilecoord$getKeyZXY} from '../../ol/tilecoord.js';
import {getKey as _ol_tilecoord$getKey} from '../../ol/tilecoord.js';
import {getCacheKeyForTileKey as _ol_tilecoord$getCacheKeyForTileKey} from '../../ol/tilecoord.js';
import {fromKey as _ol_tilecoord$fromKey} from '../../ol/tilecoord.js';
import {hash as _ol_tilecoord$hash} from '../../ol/tilecoord.js';
import {withinExtentAndZ as _ol_tilecoord$withinExtentAndZ} from '../../ol/tilecoord.js';
import {getForProjection as _ol_tilegrid$getForProjection} from '../../ol/tilegrid.js';
import {wrapX as _ol_tilegrid$wrapX} from '../../ol/tilegrid.js';
import {createForExtent as _ol_tilegrid$createForExtent} from '../../ol/tilegrid.js';
import {createXYZ as _ol_tilegrid$createXYZ} from '../../ol/tilegrid.js';
import {createForProjection as _ol_tilegrid$createForProjection} from '../../ol/tilegrid.js';
import {extentFromProjection as _ol_tilegrid$extentFromProjection} from '../../ol/tilegrid.js';
import {createFromTemplate as _ol_tileurlfunction$createFromTemplate} from '../../ol/tileurlfunction.js';
import {createFromTemplates as _ol_tileurlfunction$createFromTemplates} from '../../ol/tileurlfunction.js';
import {createFromTileUrlFunctions as _ol_tileurlfunction$createFromTileUrlFunctions} from '../../ol/tileurlfunction.js';
import {nullTileUrlFunction as _ol_tileurlfunction$nullTileUrlFunction} from '../../ol/tileurlfunction.js';
import {expandUrl as _ol_tileurlfunction$expandUrl} from '../../ol/tileurlfunction.js';
import {create as _ol_transform$create} from '../../ol/transform.js';
import {reset as _ol_transform$reset} from '../../ol/transform.js';
import {multiply as _ol_transform$multiply} from '../../ol/transform.js';
import {set as _ol_transform$set} from '../../ol/transform.js';
import {setFromArray as _ol_transform$setFromArray} from '../../ol/transform.js';
import {apply as _ol_transform$apply} from '../../ol/transform.js';
import {rotate as _ol_transform$rotate} from '../../ol/transform.js';
import {scale as _ol_transform$scale} from '../../ol/transform.js';
import {makeScale as _ol_transform$makeScale} from '../../ol/transform.js';
import {translate as _ol_transform$translate} from '../../ol/transform.js';
import {compose as _ol_transform$compose} from '../../ol/transform.js';
import {composeCssTransform as _ol_transform$composeCssTransform} from '../../ol/transform.js';
import {invert as _ol_transform$invert} from '../../ol/transform.js';
import {makeInverse as _ol_transform$makeInverse} from '../../ol/transform.js';
import {determinant as _ol_transform$determinant} from '../../ol/transform.js';
import {toString as _ol_transform$toString} from '../../ol/transform.js';
import {appendParams as _ol_uri$appendParams} from '../../ol/uri.js';
import {abstract as _ol_util$abstract} from '../../ol/util.js';
import {getUid as _ol_util$getUid} from '../../ol/util.js';
import {VERSION as _ol_util$VERSION} from '../../ol/util.js';
import {ARRAY_BUFFER as _ol_webgl$ARRAY_BUFFER} from '../../ol/webgl.js';
import {ELEMENT_ARRAY_BUFFER as _ol_webgl$ELEMENT_ARRAY_BUFFER} from '../../ol/webgl.js';
import {STREAM_DRAW as _ol_webgl$STREAM_DRAW} from '../../ol/webgl.js';
import {STATIC_DRAW as _ol_webgl$STATIC_DRAW} from '../../ol/webgl.js';
import {DYNAMIC_DRAW as _ol_webgl$DYNAMIC_DRAW} from '../../ol/webgl.js';
import {UNSIGNED_BYTE as _ol_webgl$UNSIGNED_BYTE} from '../../ol/webgl.js';
import {UNSIGNED_SHORT as _ol_webgl$UNSIGNED_SHORT} from '../../ol/webgl.js';
import {UNSIGNED_INT as _ol_webgl$UNSIGNED_INT} from '../../ol/webgl.js';
import {FLOAT as _ol_webgl$FLOAT} from '../../ol/webgl.js';
import {getContext as _ol_webgl$getContext} from '../../ol/webgl.js';
import {getSupportedExtensions as _ol_webgl$getSupportedExtensions} from '../../ol/webgl.js';
import {XML_SCHEMA_INSTANCE_URI as _ol_xml$XML_SCHEMA_INSTANCE_URI} from '../../ol/xml.js';
import {createElementNS as _ol_xml$createElementNS} from '../../ol/xml.js';
import {getAllTextContent as _ol_xml$getAllTextContent} from '../../ol/xml.js';
import {getAllTextContent_ as _ol_xml$getAllTextContent_} from '../../ol/xml.js';
import {isDocument as _ol_xml$isDocument} from '../../ol/xml.js';
import {getAttributeNS as _ol_xml$getAttributeNS} from '../../ol/xml.js';
import {parse as _ol_xml$parse} from '../../ol/xml.js';
import {makeArrayExtender as _ol_xml$makeArrayExtender} from '../../ol/xml.js';
import {makeArrayPusher as _ol_xml$makeArrayPusher} from '../../ol/xml.js';
import {makeReplacer as _ol_xml$makeReplacer} from '../../ol/xml.js';
import {makeObjectPropertyPusher as _ol_xml$makeObjectPropertyPusher} from '../../ol/xml.js';
import {makeObjectPropertySetter as _ol_xml$makeObjectPropertySetter} from '../../ol/xml.js';
import {makeChildAppender as _ol_xml$makeChildAppender} from '../../ol/xml.js';
import {makeArraySerializer as _ol_xml$makeArraySerializer} from '../../ol/xml.js';
import {makeSimpleNodeFactory as _ol_xml$makeSimpleNodeFactory} from '../../ol/xml.js';
import {OBJECT_PROPERTY_NODE_FACTORY as _ol_xml$OBJECT_PROPERTY_NODE_FACTORY} from '../../ol/xml.js';
import {makeSequence as _ol_xml$makeSequence} from '../../ol/xml.js';
import {makeStructureNS as _ol_xml$makeStructureNS} from '../../ol/xml.js';
import {parseNode as _ol_xml$parseNode} from '../../ol/xml.js';
import {pushParseAndPop as _ol_xml$pushParseAndPop} from '../../ol/xml.js';
import {serialize as _ol_xml$serialize} from '../../ol/xml.js';
import {pushSerializeAndPop as _ol_xml$pushSerializeAndPop} from '../../ol/xml.js';
import {registerXMLSerializer as _ol_xml$registerXMLSerializer} from '../../ol/xml.js';
import {getXMLSerializer as _ol_xml$getXMLSerializer} from '../../ol/xml.js';
import {registerDocument as _ol_xml$registerDocument} from '../../ol/xml.js';
import {getDocument as _ol_xml$getDocument} from '../../ol/xml.js';
import $ol$webgl$BaseTileRepresentation from '../../ol/webgl/BaseTileRepresentation.js';
import $ol$webgl$Buffer from '../../ol/webgl/Buffer.js';
import {getArrayClassForType as _ol_webgl_Buffer$getArrayClassForType} from '../../ol/webgl/Buffer.js';
import $ol$webgl$Helper from '../../ol/webgl/Helper.js';
import {computeAttributesStride as _ol_webgl_Helper$computeAttributesStride} from '../../ol/webgl/Helper.js';
import $ol$webgl$PaletteTexture from '../../ol/webgl/PaletteTexture.js';
import $ol$webgl$PostProcessingPass from '../../ol/webgl/PostProcessingPass.js';
import $ol$webgl$RenderTarget from '../../ol/webgl/RenderTarget.js';
import {COMMON_HEADER as _ol_webgl_ShaderBuilder$COMMON_HEADER} from '../../ol/webgl/ShaderBuilder.js';
import {ShaderBuilder as _ol_webgl_ShaderBuilder$ShaderBuilder} from '../../ol/webgl/ShaderBuilder.js';
import $ol$webgl$TileGeometry from '../../ol/webgl/TileGeometry.js';
import $ol$webgl$TileTexture from '../../ol/webgl/TileTexture.js';
import {expressionToGlsl as _ol_webgl_styleparser$expressionToGlsl} from '../../ol/webgl/styleparser.js';
import {packColor as _ol_webgl_styleparser$packColor} from '../../ol/webgl/styleparser.js';
import {computeHash as _ol_webgl_styleparser$computeHash} from '../../ol/webgl/styleparser.js';
import {parseLiteralStyle as _ol_webgl_styleparser$parseLiteralStyle} from '../../ol/webgl/styleparser.js';
import {create as _ol_vec_mat4$create} from '../../ol/vec/mat4.js';
import {fromTransform as _ol_vec_mat4$fromTransform} from '../../ol/vec/mat4.js';
import $ol$tilegrid$TileGrid from '../../ol/tilegrid/TileGrid.js';
import $ol$tilegrid$WMTS from '../../ol/tilegrid/WMTS.js';
import {createFromCapabilitiesMatrixSet as _ol_tilegrid_WMTS$createFromCapabilitiesMatrixSet} from '../../ol/tilegrid/WMTS.js';
import {DEFAULT_MAX_ZOOM as _ol_tilegrid_common$DEFAULT_MAX_ZOOM} from '../../ol/tilegrid/common.js';
import {DEFAULT_TILE_SIZE as _ol_tilegrid_common$DEFAULT_TILE_SIZE} from '../../ol/tilegrid/common.js';
import $ol$style$Circle from '../../ol/style/Circle.js';
import $ol$style$Fill from '../../ol/style/Fill.js';
import $ol$style$Icon from '../../ol/style/Icon.js';
import $ol$style$IconImage from '../../ol/style/IconImage.js';
import {get as _ol_style_IconImage$get} from '../../ol/style/IconImage.js';
import $ol$style$IconImageCache from '../../ol/style/IconImageCache.js';
import {getCacheKey as _ol_style_IconImageCache$getCacheKey} from '../../ol/style/IconImageCache.js';
import {shared as _ol_style_IconImageCache$shared} from '../../ol/style/IconImageCache.js';
import $ol$style$Image from '../../ol/style/Image.js';
import $ol$style$RegularShape from '../../ol/style/RegularShape.js';
import $ol$style$Stroke from '../../ol/style/Stroke.js';
import $ol$style$Style from '../../ol/style/Style.js';
import {toFunction as _ol_style_Style$toFunction} from '../../ol/style/Style.js';
import {createDefaultStyle as _ol_style_Style$createDefaultStyle} from '../../ol/style/Style.js';
import {createEditingStyle as _ol_style_Style$createEditingStyle} from '../../ol/style/Style.js';
import $ol$style$Text from '../../ol/style/Text.js';
import {createDefaultStyle as _ol_style_flat$createDefaultStyle} from '../../ol/style/flat.js';
import $ol$structs$LRUCache from '../../ol/structs/LRUCache.js';
import $ol$structs$LinkedList from '../../ol/structs/LinkedList.js';
import {DROP as _ol_structs_PriorityQueue$DROP} from '../../ol/structs/PriorityQueue.js';
import $ol$structs$PriorityQueue from '../../ol/structs/PriorityQueue.js';
import $ol$structs$RBush from '../../ol/structs/RBush.js';
import {quadKey as _ol_source_BingMaps$quadKey} from '../../ol/source/BingMaps.js';
import $ol$source$BingMaps from '../../ol/source/BingMaps.js';
import $ol$source$CartoDB from '../../ol/source/CartoDB.js';
import $ol$source$Cluster from '../../ol/source/Cluster.js';
import $ol$source$DataTile from '../../ol/source/DataTile.js';
import $ol$source$GeoTIFF from '../../ol/source/GeoTIFF.js';
import $ol$source$Google from '../../ol/source/Google.js';
import $ol$source$IIIF from '../../ol/source/IIIF.js';
import {ImageSourceEvent as _ol_source_Image$ImageSourceEvent} from '../../ol/source/Image.js';
import $ol$source$Image from '../../ol/source/Image.js';
import {defaultImageLoadFunction as _ol_source_Image$defaultImageLoadFunction} from '../../ol/source/Image.js';
import {getRequestExtent as _ol_source_Image$getRequestExtent} from '../../ol/source/Image.js';
import $ol$source$ImageArcGISRest from '../../ol/source/ImageArcGISRest.js';
import $ol$source$ImageCanvas from '../../ol/source/ImageCanvas.js';
import $ol$source$ImageMapGuide from '../../ol/source/ImageMapGuide.js';
import $ol$source$ImageStatic from '../../ol/source/ImageStatic.js';
import $ol$source$ImageWMS from '../../ol/source/ImageWMS.js';
import $ol$source$OGCMapTile from '../../ol/source/OGCMapTile.js';
import $ol$source$OGCVectorTile from '../../ol/source/OGCVectorTile.js';
import {ATTRIBUTION as _ol_source_OSM$ATTRIBUTION} from '../../ol/source/OSM.js';
import $ol$source$OSM from '../../ol/source/OSM.js';
import {newImageData as _ol_source_Raster$newImageData} from '../../ol/source/Raster.js';
import {Processor as _ol_source_Raster$Processor} from '../../ol/source/Raster.js';
import {RasterSourceEvent as _ol_source_Raster$RasterSourceEvent} from '../../ol/source/Raster.js';
import $ol$source$Raster from '../../ol/source/Raster.js';
import $ol$source$Source from '../../ol/source/Source.js';
import $ol$source$StadiaMaps from '../../ol/source/StadiaMaps.js';
import $ol$source$Tile from '../../ol/source/Tile.js';
import {TileSourceEvent as _ol_source_Tile$TileSourceEvent} from '../../ol/source/Tile.js';
import $ol$source$TileArcGISRest from '../../ol/source/TileArcGISRest.js';
import $ol$source$TileDebug from '../../ol/source/TileDebug.js';
import $ol$source$TileImage from '../../ol/source/TileImage.js';
import $ol$source$TileJSON from '../../ol/source/TileJSON.js';
import $ol$source$TileWMS from '../../ol/source/TileWMS.js';
import {CustomTile as _ol_source_UTFGrid$CustomTile} from '../../ol/source/UTFGrid.js';
import $ol$source$UTFGrid from '../../ol/source/UTFGrid.js';
import $ol$source$UrlTile from '../../ol/source/UrlTile.js';
import {VectorSourceEvent as _ol_source_Vector$VectorSourceEvent} from '../../ol/source/Vector.js';
import $ol$source$Vector from '../../ol/source/Vector.js';
import $ol$source$VectorTile from '../../ol/source/VectorTile.js';
import {defaultLoadFunction as _ol_source_VectorTile$defaultLoadFunction} from '../../ol/source/VectorTile.js';
import $ol$source$WMTS from '../../ol/source/WMTS.js';
import {optionsFromCapabilities as _ol_source_WMTS$optionsFromCapabilities} from '../../ol/source/WMTS.js';
import $ol$source$XYZ from '../../ol/source/XYZ.js';
import {CustomTile as _ol_source_Zoomify$CustomTile} from '../../ol/source/Zoomify.js';
import $ol$source$Zoomify from '../../ol/source/Zoomify.js';
import {getRequestUrl as _ol_source_arcgisRest$getRequestUrl} from '../../ol/source/arcgisRest.js';
import {createLoader as _ol_source_arcgisRest$createLoader} from '../../ol/source/arcgisRest.js';
import {DEFAULT_WMS_VERSION as _ol_source_common$DEFAULT_WMS_VERSION} from '../../ol/source/common.js';
import {DECIMALS as _ol_source_common$DECIMALS} from '../../ol/source/common.js';
import {createLoader as _ol_source_mapguide$createLoader} from '../../ol/source/mapguide.js';
import {appendCollectionsQueryParam as _ol_source_ogcTileUtil$appendCollectionsQueryParam} from '../../ol/source/ogcTileUtil.js';
import {getMapTileUrlTemplate as _ol_source_ogcTileUtil$getMapTileUrlTemplate} from '../../ol/source/ogcTileUtil.js';
import {getVectorTileUrlTemplate as _ol_source_ogcTileUtil$getVectorTileUrlTemplate} from '../../ol/source/ogcTileUtil.js';
import {getTileSetInfo as _ol_source_ogcTileUtil$getTileSetInfo} from '../../ol/source/ogcTileUtil.js';
import {createLoader as _ol_source_static$createLoader} from '../../ol/source/static.js';
import {DEFAULT_VERSION as _ol_source_wms$DEFAULT_VERSION} from '../../ol/source/wms.js';
import {getRequestUrl as _ol_source_wms$getRequestUrl} from '../../ol/source/wms.js';
import {getImageSrc as _ol_source_wms$getImageSrc} from '../../ol/source/wms.js';
import {getRequestParams as _ol_source_wms$getRequestParams} from '../../ol/source/wms.js';
import {createLoader as _ol_source_wms$createLoader} from '../../ol/source/wms.js';
import {getFeatureInfoUrl as _ol_source_wms$getFeatureInfoUrl} from '../../ol/source/wms.js';
import {getLegendUrl as _ol_source_wms$getLegendUrl} from '../../ol/source/wms.js';
import $ol$reproj$DataTile from '../../ol/reproj/DataTile.js';
import $ol$reproj$Image from '../../ol/reproj/Image.js';
import $ol$reproj$Tile from '../../ol/reproj/Tile.js';
import $ol$reproj$Triangulation from '../../ol/reproj/Triangulation.js';
import {ERROR_THRESHOLD as _ol_reproj_common$ERROR_THRESHOLD} from '../../ol/reproj/common.js';
import $ol$renderer$Composite from '../../ol/renderer/Composite.js';
import $ol$renderer$Layer from '../../ol/renderer/Layer.js';
import $ol$renderer$Map from '../../ol/renderer/Map.js';
import {defaultOrder as _ol_renderer_vector$defaultOrder} from '../../ol/renderer/vector.js';
import {getSquaredTolerance as _ol_renderer_vector$getSquaredTolerance} from '../../ol/renderer/vector.js';
import {getTolerance as _ol_renderer_vector$getTolerance} from '../../ol/renderer/vector.js';
import {renderFeature as _ol_renderer_vector$renderFeature} from '../../ol/renderer/vector.js';
import $ol$renderer$webgl$Layer from '../../ol/renderer/webgl/Layer.js';
import $ol$renderer$webgl$PointsLayer from '../../ol/renderer/webgl/PointsLayer.js';
import {Uniforms as _ol_renderer_webgl_TileLayer$Uniforms} from '../../ol/renderer/webgl/TileLayer.js';
import {Attributes as _ol_renderer_webgl_TileLayer$Attributes} from '../../ol/renderer/webgl/TileLayer.js';
import $ol$renderer$webgl$TileLayer from '../../ol/renderer/webgl/TileLayer.js';
import {Uniforms as _ol_renderer_webgl_TileLayerBase$Uniforms} from '../../ol/renderer/webgl/TileLayerBase.js';
import {newTileRepresentationLookup as _ol_renderer_webgl_TileLayerBase$newTileRepresentationLookup} from '../../ol/renderer/webgl/TileLayerBase.js';
import {getCacheKey as _ol_renderer_webgl_TileLayerBase$getCacheKey} from '../../ol/renderer/webgl/TileLayerBase.js';
import $ol$renderer$webgl$TileLayerBase from '../../ol/renderer/webgl/TileLayerBase.js';
import {Uniforms as _ol_renderer_webgl_VectorLayer$Uniforms} from '../../ol/renderer/webgl/VectorLayer.js';
import $ol$renderer$webgl$VectorLayer from '../../ol/renderer/webgl/VectorLayer.js';
import {Uniforms as _ol_renderer_webgl_VectorTileLayer$Uniforms} from '../../ol/renderer/webgl/VectorTileLayer.js';
import {Attributes as _ol_renderer_webgl_VectorTileLayer$Attributes} from '../../ol/renderer/webgl/VectorTileLayer.js';
import $ol$renderer$webgl$VectorTileLayer from '../../ol/renderer/webgl/VectorTileLayer.js';
import $ol$renderer$canvas$ImageLayer from '../../ol/renderer/canvas/ImageLayer.js';
import {canvasPool as _ol_renderer_canvas_Layer$canvasPool} from '../../ol/renderer/canvas/Layer.js';
import $ol$renderer$canvas$Layer from '../../ol/renderer/canvas/Layer.js';
import $ol$renderer$canvas$TileLayer from '../../ol/renderer/canvas/TileLayer.js';
import $ol$renderer$canvas$VectorImageLayer from '../../ol/renderer/canvas/VectorImageLayer.js';
import $ol$renderer$canvas$VectorLayer from '../../ol/renderer/canvas/VectorLayer.js';
import $ol$renderer$canvas$VectorTileLayer from '../../ol/renderer/canvas/VectorTileLayer.js';
import $ol$render$Box from '../../ol/render/Box.js';
import $ol$render$Event from '../../ol/render/Event.js';
import $ol$render$Feature from '../../ol/render/Feature.js';
import {toGeometry as _ol_render_Feature$toGeometry} from '../../ol/render/Feature.js';
import {toFeature as _ol_render_Feature$toFeature} from '../../ol/render/Feature.js';
import $ol$render$VectorContext from '../../ol/render/VectorContext.js';
import {defaultFont as _ol_render_canvas$defaultFont} from '../../ol/render/canvas.js';
import {defaultFillStyle as _ol_render_canvas$defaultFillStyle} from '../../ol/render/canvas.js';
import {defaultLineCap as _ol_render_canvas$defaultLineCap} from '../../ol/render/canvas.js';
import {defaultLineDash as _ol_render_canvas$defaultLineDash} from '../../ol/render/canvas.js';
import {defaultLineDashOffset as _ol_render_canvas$defaultLineDashOffset} from '../../ol/render/canvas.js';
import {defaultLineJoin as _ol_render_canvas$defaultLineJoin} from '../../ol/render/canvas.js';
import {defaultMiterLimit as _ol_render_canvas$defaultMiterLimit} from '../../ol/render/canvas.js';
import {defaultStrokeStyle as _ol_render_canvas$defaultStrokeStyle} from '../../ol/render/canvas.js';
import {defaultTextAlign as _ol_render_canvas$defaultTextAlign} from '../../ol/render/canvas.js';
import {defaultTextBaseline as _ol_render_canvas$defaultTextBaseline} from '../../ol/render/canvas.js';
import {defaultPadding as _ol_render_canvas$defaultPadding} from '../../ol/render/canvas.js';
import {defaultLineWidth as _ol_render_canvas$defaultLineWidth} from '../../ol/render/canvas.js';
import {checkedFonts as _ol_render_canvas$checkedFonts} from '../../ol/render/canvas.js';
import {textHeights as _ol_render_canvas$textHeights} from '../../ol/render/canvas.js';
import {registerFont as _ol_render_canvas$registerFont} from '../../ol/render/canvas.js';
import {measureTextHeight as _ol_render_canvas$measureTextHeight} from '../../ol/render/canvas.js';
import {measureTextWidth as _ol_render_canvas$measureTextWidth} from '../../ol/render/canvas.js';
import {measureAndCacheTextWidth as _ol_render_canvas$measureAndCacheTextWidth} from '../../ol/render/canvas.js';
import {getTextDimensions as _ol_render_canvas$getTextDimensions} from '../../ol/render/canvas.js';
import {rotateAtOffset as _ol_render_canvas$rotateAtOffset} from '../../ol/render/canvas.js';
import {drawImageOrLabel as _ol_render_canvas$drawImageOrLabel} from '../../ol/render/canvas.js';
import $ol$render$webgl$MixedGeometryBatch from '../../ol/render/webgl/MixedGeometryBatch.js';
import $ol$render$webgl$VectorStyleRenderer from '../../ol/render/webgl/VectorStyleRenderer.js';
import {getCustomAttributesSize as _ol_render_webgl_renderinstructions$getCustomAttributesSize} from '../../ol/render/webgl/renderinstructions.js';
import {generatePointRenderInstructions as _ol_render_webgl_renderinstructions$generatePointRenderInstructions} from '../../ol/render/webgl/renderinstructions.js';
import {generateLineStringRenderInstructions as _ol_render_webgl_renderinstructions$generateLineStringRenderInstructions} from '../../ol/render/webgl/renderinstructions.js';
import {generatePolygonRenderInstructions as _ol_render_webgl_renderinstructions$generatePolygonRenderInstructions} from '../../ol/render/webgl/renderinstructions.js';
import {LINESTRING_ANGLE_COSINE_CUTOFF as _ol_render_webgl_utils$LINESTRING_ANGLE_COSINE_CUTOFF} from '../../ol/render/webgl/utils.js';
import {writePointFeatureToBuffers as _ol_render_webgl_utils$writePointFeatureToBuffers} from '../../ol/render/webgl/utils.js';
import {writeLineSegmentToBuffers as _ol_render_webgl_utils$writeLineSegmentToBuffers} from '../../ol/render/webgl/utils.js';
import {writePolygonTrianglesToBuffers as _ol_render_webgl_utils$writePolygonTrianglesToBuffers} from '../../ol/render/webgl/utils.js';
import {getBlankImageData as _ol_render_webgl_utils$getBlankImageData} from '../../ol/render/webgl/utils.js';
import {colorEncodeId as _ol_render_webgl_utils$colorEncodeId} from '../../ol/render/webgl/utils.js';
import {colorDecodeId as _ol_render_webgl_utils$colorDecodeId} from '../../ol/render/webgl/utils.js';
import $ol$render$canvas$Builder from '../../ol/render/canvas/Builder.js';
import $ol$render$canvas$BuilderGroup from '../../ol/render/canvas/BuilderGroup.js';
import $ol$render$canvas$Executor from '../../ol/render/canvas/Executor.js';
import {ALL as _ol_render_canvas_ExecutorGroup$ALL} from '../../ol/render/canvas/ExecutorGroup.js';
import {DECLUTTER as _ol_render_canvas_ExecutorGroup$DECLUTTER} from '../../ol/render/canvas/ExecutorGroup.js';
import {NON_DECLUTTER as _ol_render_canvas_ExecutorGroup$NON_DECLUTTER} from '../../ol/render/canvas/ExecutorGroup.js';
import $ol$render$canvas$ExecutorGroup from '../../ol/render/canvas/ExecutorGroup.js';
import {getPixelIndexArray as _ol_render_canvas_ExecutorGroup$getPixelIndexArray} from '../../ol/render/canvas/ExecutorGroup.js';
import $ol$render$canvas$ImageBuilder from '../../ol/render/canvas/ImageBuilder.js';
import $ol$render$canvas$Immediate from '../../ol/render/canvas/Immediate.js';
import {fillInstruction as _ol_render_canvas_Instruction$fillInstruction} from '../../ol/render/canvas/Instruction.js';
import {strokeInstruction as _ol_render_canvas_Instruction$strokeInstruction} from '../../ol/render/canvas/Instruction.js';
import {beginPathInstruction as _ol_render_canvas_Instruction$beginPathInstruction} from '../../ol/render/canvas/Instruction.js';
import {closePathInstruction as _ol_render_canvas_Instruction$closePathInstruction} from '../../ol/render/canvas/Instruction.js';
import $ol$render$canvas$LineStringBuilder from '../../ol/render/canvas/LineStringBuilder.js';
import $ol$render$canvas$PolygonBuilder from '../../ol/render/canvas/PolygonBuilder.js';
import {TEXT_ALIGN as _ol_render_canvas_TextBuilder$TEXT_ALIGN} from '../../ol/render/canvas/TextBuilder.js';
import $ol$render$canvas$TextBuilder from '../../ol/render/canvas/TextBuilder.js';
import $ol$render$canvas$ZIndexContext from '../../ol/render/canvas/ZIndexContext.js';
import {HIT_DETECT_RESOLUTION as _ol_render_canvas_hitdetect$HIT_DETECT_RESOLUTION} from '../../ol/render/canvas/hitdetect.js';
import {createHitDetectionImageData as _ol_render_canvas_hitdetect$createHitDetectionImageData} from '../../ol/render/canvas/hitdetect.js';
import {hitDetect as _ol_render_canvas_hitdetect$hitDetect} from '../../ol/render/canvas/hitdetect.js';
import {rulesToStyleFunction as _ol_render_canvas_style$rulesToStyleFunction} from '../../ol/render/canvas/style.js';
import {flatStylesToStyleFunction as _ol_render_canvas_style$flatStylesToStyleFunction} from '../../ol/render/canvas/style.js';
import {buildRuleSet as _ol_render_canvas_style$buildRuleSet} from '../../ol/render/canvas/style.js';
import {buildStyle as _ol_render_canvas_style$buildStyle} from '../../ol/render/canvas/style.js';
import $ol$proj$Projection from '../../ol/proj/Projection.js';
import {fromCode as _ol_proj_Units$fromCode} from '../../ol/proj/Units.js';
import {METERS_PER_UNIT as _ol_proj_Units$METERS_PER_UNIT} from '../../ol/proj/Units.js';
import {RADIUS as _ol_proj_epsg3857$RADIUS} from '../../ol/proj/epsg3857.js';
import {HALF_SIZE as _ol_proj_epsg3857$HALF_SIZE} from '../../ol/proj/epsg3857.js';
import {EXTENT as _ol_proj_epsg3857$EXTENT} from '../../ol/proj/epsg3857.js';
import {WORLD_EXTENT as _ol_proj_epsg3857$WORLD_EXTENT} from '../../ol/proj/epsg3857.js';
import {MAX_SAFE_Y as _ol_proj_epsg3857$MAX_SAFE_Y} from '../../ol/proj/epsg3857.js';
import {PROJECTIONS as _ol_proj_epsg3857$PROJECTIONS} from '../../ol/proj/epsg3857.js';
import {fromEPSG4326 as _ol_proj_epsg3857$fromEPSG4326} from '../../ol/proj/epsg3857.js';
import {toEPSG4326 as _ol_proj_epsg3857$toEPSG4326} from '../../ol/proj/epsg3857.js';
import {RADIUS as _ol_proj_epsg4326$RADIUS} from '../../ol/proj/epsg4326.js';
import {EXTENT as _ol_proj_epsg4326$EXTENT} from '../../ol/proj/epsg4326.js';
import {METERS_PER_UNIT as _ol_proj_epsg4326$METERS_PER_UNIT} from '../../ol/proj/epsg4326.js';
import {PROJECTIONS as _ol_proj_epsg4326$PROJECTIONS} from '../../ol/proj/epsg4326.js';
import {isRegistered as _ol_proj_proj4$isRegistered} from '../../ol/proj/proj4.js';
import {unregister as _ol_proj_proj4$unregister} from '../../ol/proj/proj4.js';
import {register as _ol_proj_proj4$register} from '../../ol/proj/proj4.js';
import {setEPSGLookup as _ol_proj_proj4$setEPSGLookup} from '../../ol/proj/proj4.js';
import {getEPSGLookup as _ol_proj_proj4$getEPSGLookup} from '../../ol/proj/proj4.js';
import {fromEPSGCode as _ol_proj_proj4$fromEPSGCode} from '../../ol/proj/proj4.js';
import {epsgLookupMapTiler as _ol_proj_proj4$epsgLookupMapTiler} from '../../ol/proj/proj4.js';
import {clear as _ol_proj_projections$clear} from '../../ol/proj/projections.js';
import {get as _ol_proj_projections$get} from '../../ol/proj/projections.js';
import {add as _ol_proj_projections$add} from '../../ol/proj/projections.js';
import {clear as _ol_proj_transforms$clear} from '../../ol/proj/transforms.js';
import {add as _ol_proj_transforms$add} from '../../ol/proj/transforms.js';
import {remove as _ol_proj_transforms$remove} from '../../ol/proj/transforms.js';
import {get as _ol_proj_transforms$get} from '../../ol/proj/transforms.js';
import $ol$layer$Base from '../../ol/layer/Base.js';
import $ol$layer$BaseImage from '../../ol/layer/BaseImage.js';
import $ol$layer$BaseTile from '../../ol/layer/BaseTile.js';
import $ol$layer$BaseVector from '../../ol/layer/BaseVector.js';
import $ol$layer$Graticule from '../../ol/layer/Graticule.js';
import {GroupEvent as _ol_layer_Group$GroupEvent} from '../../ol/layer/Group.js';
import $ol$layer$Group from '../../ol/layer/Group.js';
import $ol$layer$Heatmap from '../../ol/layer/Heatmap.js';
import $ol$layer$Image from '../../ol/layer/Image.js';
import $ol$layer$Layer from '../../ol/layer/Layer.js';
import {inView as _ol_layer_Layer$inView} from '../../ol/layer/Layer.js';
import $ol$layer$Tile from '../../ol/layer/Tile.js';
import $ol$layer$Vector from '../../ol/layer/Vector.js';
import $ol$layer$VectorImage from '../../ol/layer/VectorImage.js';
import $ol$layer$VectorTile from '../../ol/layer/VectorTile.js';
import $ol$layer$WebGLPoints from '../../ol/layer/WebGLPoints.js';
import $ol$layer$WebGLTile from '../../ol/layer/WebGLTile.js';
import $ol$interaction$DblClickDragZoom from '../../ol/interaction/DblClickDragZoom.js';
import $ol$interaction$DoubleClickZoom from '../../ol/interaction/DoubleClickZoom.js';
import {DragAndDropEvent as _ol_interaction_DragAndDrop$DragAndDropEvent} from '../../ol/interaction/DragAndDrop.js';
import $ol$interaction$DragAndDrop from '../../ol/interaction/DragAndDrop.js';
import {DragBoxEvent as _ol_interaction_DragBox$DragBoxEvent} from '../../ol/interaction/DragBox.js';
import $ol$interaction$DragBox from '../../ol/interaction/DragBox.js';
import $ol$interaction$DragPan from '../../ol/interaction/DragPan.js';
import $ol$interaction$DragRotate from '../../ol/interaction/DragRotate.js';
import $ol$interaction$DragRotateAndZoom from '../../ol/interaction/DragRotateAndZoom.js';
import $ol$interaction$DragZoom from '../../ol/interaction/DragZoom.js';
import {DrawEvent as _ol_interaction_Draw$DrawEvent} from '../../ol/interaction/Draw.js';
import $ol$interaction$Draw from '../../ol/interaction/Draw.js';
import {createRegularPolygon as _ol_interaction_Draw$createRegularPolygon} from '../../ol/interaction/Draw.js';
import {createBox as _ol_interaction_Draw$createBox} from '../../ol/interaction/Draw.js';
import {ExtentEvent as _ol_interaction_Extent$ExtentEvent} from '../../ol/interaction/Extent.js';
import $ol$interaction$Extent from '../../ol/interaction/Extent.js';
import $ol$interaction$Interaction from '../../ol/interaction/Interaction.js';
import {pan as _ol_interaction_Interaction$pan} from '../../ol/interaction/Interaction.js';
import {zoomByDelta as _ol_interaction_Interaction$zoomByDelta} from '../../ol/interaction/Interaction.js';
import $ol$interaction$KeyboardPan from '../../ol/interaction/KeyboardPan.js';
import $ol$interaction$KeyboardZoom from '../../ol/interaction/KeyboardZoom.js';
import $ol$interaction$Link from '../../ol/interaction/Link.js';
import {ModifyEvent as _ol_interaction_Modify$ModifyEvent} from '../../ol/interaction/Modify.js';
import $ol$interaction$Modify from '../../ol/interaction/Modify.js';
import $ol$interaction$MouseWheelZoom from '../../ol/interaction/MouseWheelZoom.js';
import $ol$interaction$PinchRotate from '../../ol/interaction/PinchRotate.js';
import $ol$interaction$PinchZoom from '../../ol/interaction/PinchZoom.js';
import $ol$interaction$Pointer from '../../ol/interaction/Pointer.js';
import {centroid as _ol_interaction_Pointer$centroid} from '../../ol/interaction/Pointer.js';
import {SelectEvent as _ol_interaction_Select$SelectEvent} from '../../ol/interaction/Select.js';
import $ol$interaction$Select from '../../ol/interaction/Select.js';
import $ol$interaction$Snap from '../../ol/interaction/Snap.js';
import {TranslateEvent as _ol_interaction_Translate$TranslateEvent} from '../../ol/interaction/Translate.js';
import $ol$interaction$Translate from '../../ol/interaction/Translate.js';
import {defaults as _ol_interaction_defaults$defaults} from '../../ol/interaction/defaults.js';
import $ol$geom$Circle from '../../ol/geom/Circle.js';
import $ol$geom$Geometry from '../../ol/geom/Geometry.js';
import $ol$geom$GeometryCollection from '../../ol/geom/GeometryCollection.js';
import $ol$geom$LineString from '../../ol/geom/LineString.js';
import $ol$geom$LinearRing from '../../ol/geom/LinearRing.js';
import $ol$geom$MultiLineString from '../../ol/geom/MultiLineString.js';
import $ol$geom$MultiPoint from '../../ol/geom/MultiPoint.js';
import $ol$geom$MultiPolygon from '../../ol/geom/MultiPolygon.js';
import $ol$geom$Point from '../../ol/geom/Point.js';
import $ol$geom$Polygon from '../../ol/geom/Polygon.js';
import {circular as _ol_geom_Polygon$circular} from '../../ol/geom/Polygon.js';
import {fromExtent as _ol_geom_Polygon$fromExtent} from '../../ol/geom/Polygon.js';
import {fromCircle as _ol_geom_Polygon$fromCircle} from '../../ol/geom/Polygon.js';
import {makeRegular as _ol_geom_Polygon$makeRegular} from '../../ol/geom/Polygon.js';
import $ol$geom$SimpleGeometry from '../../ol/geom/SimpleGeometry.js';
import {getLayoutForStride as _ol_geom_SimpleGeometry$getLayoutForStride} from '../../ol/geom/SimpleGeometry.js';
import {getStrideForLayout as _ol_geom_SimpleGeometry$getStrideForLayout} from '../../ol/geom/SimpleGeometry.js';
import {transformGeom2D as _ol_geom_SimpleGeometry$transformGeom2D} from '../../ol/geom/SimpleGeometry.js';
import {linearRing as _ol_geom_flat_area$linearRing} from '../../ol/geom/flat/area.js';
import {linearRings as _ol_geom_flat_area$linearRings} from '../../ol/geom/flat/area.js';
import {linearRingss as _ol_geom_flat_area$linearRingss} from '../../ol/geom/flat/area.js';
import {linearRingss as _ol_geom_flat_center$linearRingss} from '../../ol/geom/flat/center.js';
import {maxSquaredDelta as _ol_geom_flat_closest$maxSquaredDelta} from '../../ol/geom/flat/closest.js';
import {arrayMaxSquaredDelta as _ol_geom_flat_closest$arrayMaxSquaredDelta} from '../../ol/geom/flat/closest.js';
import {multiArrayMaxSquaredDelta as _ol_geom_flat_closest$multiArrayMaxSquaredDelta} from '../../ol/geom/flat/closest.js';
import {assignClosestPoint as _ol_geom_flat_closest$assignClosestPoint} from '../../ol/geom/flat/closest.js';
import {assignClosestArrayPoint as _ol_geom_flat_closest$assignClosestArrayPoint} from '../../ol/geom/flat/closest.js';
import {assignClosestMultiArrayPoint as _ol_geom_flat_closest$assignClosestMultiArrayPoint} from '../../ol/geom/flat/closest.js';
import {linearRingContainsExtent as _ol_geom_flat_contains$linearRingContainsExtent} from '../../ol/geom/flat/contains.js';
import {linearRingContainsXY as _ol_geom_flat_contains$linearRingContainsXY} from '../../ol/geom/flat/contains.js';
import {linearRingsContainsXY as _ol_geom_flat_contains$linearRingsContainsXY} from '../../ol/geom/flat/contains.js';
import {linearRingssContainsXY as _ol_geom_flat_contains$linearRingssContainsXY} from '../../ol/geom/flat/contains.js';
import {deflateCoordinate as _ol_geom_flat_deflate$deflateCoordinate} from '../../ol/geom/flat/deflate.js';
import {deflateCoordinates as _ol_geom_flat_deflate$deflateCoordinates} from '../../ol/geom/flat/deflate.js';
import {deflateCoordinatesArray as _ol_geom_flat_deflate$deflateCoordinatesArray} from '../../ol/geom/flat/deflate.js';
import {deflateMultiCoordinatesArray as _ol_geom_flat_deflate$deflateMultiCoordinatesArray} from '../../ol/geom/flat/deflate.js';
import {flipXY as _ol_geom_flat_flip$flipXY} from '../../ol/geom/flat/flip.js';
import {greatCircleArc as _ol_geom_flat_geodesic$greatCircleArc} from '../../ol/geom/flat/geodesic.js';
import {meridian as _ol_geom_flat_geodesic$meridian} from '../../ol/geom/flat/geodesic.js';
import {parallel as _ol_geom_flat_geodesic$parallel} from '../../ol/geom/flat/geodesic.js';
import {inflateCoordinates as _ol_geom_flat_inflate$inflateCoordinates} from '../../ol/geom/flat/inflate.js';
import {inflateCoordinatesArray as _ol_geom_flat_inflate$inflateCoordinatesArray} from '../../ol/geom/flat/inflate.js';
import {inflateMultiCoordinatesArray as _ol_geom_flat_inflate$inflateMultiCoordinatesArray} from '../../ol/geom/flat/inflate.js';
import {getInteriorPointOfArray as _ol_geom_flat_interiorpoint$getInteriorPointOfArray} from '../../ol/geom/flat/interiorpoint.js';
import {getInteriorPointsOfMultiArray as _ol_geom_flat_interiorpoint$getInteriorPointsOfMultiArray} from '../../ol/geom/flat/interiorpoint.js';
import {interpolatePoint as _ol_geom_flat_interpolate$interpolatePoint} from '../../ol/geom/flat/interpolate.js';
import {lineStringCoordinateAtM as _ol_geom_flat_interpolate$lineStringCoordinateAtM} from '../../ol/geom/flat/interpolate.js';
import {lineStringsCoordinateAtM as _ol_geom_flat_interpolate$lineStringsCoordinateAtM} from '../../ol/geom/flat/interpolate.js';
import {intersectsLineString as _ol_geom_flat_intersectsextent$intersectsLineString} from '../../ol/geom/flat/intersectsextent.js';
import {intersectsLineStringArray as _ol_geom_flat_intersectsextent$intersectsLineStringArray} from '../../ol/geom/flat/intersectsextent.js';
import {intersectsLinearRing as _ol_geom_flat_intersectsextent$intersectsLinearRing} from '../../ol/geom/flat/intersectsextent.js';
import {intersectsLinearRingArray as _ol_geom_flat_intersectsextent$intersectsLinearRingArray} from '../../ol/geom/flat/intersectsextent.js';
import {intersectsLinearRingMultiArray as _ol_geom_flat_intersectsextent$intersectsLinearRingMultiArray} from '../../ol/geom/flat/intersectsextent.js';
import {lineStringLength as _ol_geom_flat_length$lineStringLength} from '../../ol/geom/flat/length.js';
import {linearRingLength as _ol_geom_flat_length$linearRingLength} from '../../ol/geom/flat/length.js';
import {linearRingIsClockwise as _ol_geom_flat_orient$linearRingIsClockwise} from '../../ol/geom/flat/orient.js';
import {linearRingsAreOriented as _ol_geom_flat_orient$linearRingsAreOriented} from '../../ol/geom/flat/orient.js';
import {linearRingssAreOriented as _ol_geom_flat_orient$linearRingssAreOriented} from '../../ol/geom/flat/orient.js';
import {orientLinearRings as _ol_geom_flat_orient$orientLinearRings} from '../../ol/geom/flat/orient.js';
import {orientLinearRingsArray as _ol_geom_flat_orient$orientLinearRingsArray} from '../../ol/geom/flat/orient.js';
import {inflateEnds as _ol_geom_flat_orient$inflateEnds} from '../../ol/geom/flat/orient.js';
import {coordinates as _ol_geom_flat_reverse$coordinates} from '../../ol/geom/flat/reverse.js';
import {forEach as _ol_geom_flat_segments$forEach} from '../../ol/geom/flat/segments.js';
import {simplifyLineString as _ol_geom_flat_simplify$simplifyLineString} from '../../ol/geom/flat/simplify.js';
import {douglasPeucker as _ol_geom_flat_simplify$douglasPeucker} from '../../ol/geom/flat/simplify.js';
import {douglasPeuckerArray as _ol_geom_flat_simplify$douglasPeuckerArray} from '../../ol/geom/flat/simplify.js';
import {douglasPeuckerMultiArray as _ol_geom_flat_simplify$douglasPeuckerMultiArray} from '../../ol/geom/flat/simplify.js';
import {radialDistance as _ol_geom_flat_simplify$radialDistance} from '../../ol/geom/flat/simplify.js';
import {snap as _ol_geom_flat_simplify$snap} from '../../ol/geom/flat/simplify.js';
import {quantize as _ol_geom_flat_simplify$quantize} from '../../ol/geom/flat/simplify.js';
import {quantizeArray as _ol_geom_flat_simplify$quantizeArray} from '../../ol/geom/flat/simplify.js';
import {quantizeMultiArray as _ol_geom_flat_simplify$quantizeMultiArray} from '../../ol/geom/flat/simplify.js';
import {matchingChunk as _ol_geom_flat_straightchunk$matchingChunk} from '../../ol/geom/flat/straightchunk.js';
import {drawTextOnPath as _ol_geom_flat_textpath$drawTextOnPath} from '../../ol/geom/flat/textpath.js';
import {lineStringIsClosed as _ol_geom_flat_topology$lineStringIsClosed} from '../../ol/geom/flat/topology.js';
import {transform2D as _ol_geom_flat_transform$transform2D} from '../../ol/geom/flat/transform.js';
import {rotate as _ol_geom_flat_transform$rotate} from '../../ol/geom/flat/transform.js';
import {scale as _ol_geom_flat_transform$scale} from '../../ol/geom/flat/transform.js';
import {translate as _ol_geom_flat_transform$translate} from '../../ol/geom/flat/transform.js';
import $ol$format$EsriJSON from '../../ol/format/EsriJSON.js';
import $ol$format$Feature from '../../ol/format/Feature.js';
import {transformGeometryWithOptions as _ol_format_Feature$transformGeometryWithOptions} from '../../ol/format/Feature.js';
import {transformExtentWithOptions as _ol_format_Feature$transformExtentWithOptions} from '../../ol/format/Feature.js';
import {createRenderFeature as _ol_format_Feature$createRenderFeature} from '../../ol/format/Feature.js';
import {createGeometry as _ol_format_Feature$createGeometry} from '../../ol/format/Feature.js';
import $ol$format$GML from '../../ol/format/GML.js';
import $ol$format$GML2 from '../../ol/format/GML2.js';
import $ol$format$GML3 from '../../ol/format/GML3.js';
import $ol$format$GML32 from '../../ol/format/GML32.js';
import {GMLNS as _ol_format_GMLBase$GMLNS} from '../../ol/format/GMLBase.js';
import $ol$format$GMLBase from '../../ol/format/GMLBase.js';
import $ol$format$GPX from '../../ol/format/GPX.js';
import $ol$format$GeoJSON from '../../ol/format/GeoJSON.js';
import $ol$format$IGC from '../../ol/format/IGC.js';
import $ol$format$IIIFInfo from '../../ol/format/IIIFInfo.js';
import $ol$format$JSONFeature from '../../ol/format/JSONFeature.js';
import {getDefaultFillStyle as _ol_format_KML$getDefaultFillStyle} from '../../ol/format/KML.js';
import {getDefaultImageStyle as _ol_format_KML$getDefaultImageStyle} from '../../ol/format/KML.js';
import {getDefaultStrokeStyle as _ol_format_KML$getDefaultStrokeStyle} from '../../ol/format/KML.js';
import {getDefaultTextStyle as _ol_format_KML$getDefaultTextStyle} from '../../ol/format/KML.js';
import {getDefaultStyle as _ol_format_KML$getDefaultStyle} from '../../ol/format/KML.js';
import {getDefaultStyleArray as _ol_format_KML$getDefaultStyleArray} from '../../ol/format/KML.js';
import $ol$format$KML from '../../ol/format/KML.js';
import {readFlatCoordinates as _ol_format_KML$readFlatCoordinates} from '../../ol/format/KML.js';
import $ol$format$MVT from '../../ol/format/MVT.js';
import $ol$format$OSMXML from '../../ol/format/OSMXML.js';
import $ol$format$OWS from '../../ol/format/OWS.js';
import $ol$format$Polyline from '../../ol/format/Polyline.js';
import {encodeDeltas as _ol_format_Polyline$encodeDeltas} from '../../ol/format/Polyline.js';
import {decodeDeltas as _ol_format_Polyline$decodeDeltas} from '../../ol/format/Polyline.js';
import {encodeFloats as _ol_format_Polyline$encodeFloats} from '../../ol/format/Polyline.js';
import {decodeFloats as _ol_format_Polyline$decodeFloats} from '../../ol/format/Polyline.js';
import {encodeSignedIntegers as _ol_format_Polyline$encodeSignedIntegers} from '../../ol/format/Polyline.js';
import {decodeSignedIntegers as _ol_format_Polyline$decodeSignedIntegers} from '../../ol/format/Polyline.js';
import {encodeUnsignedIntegers as _ol_format_Polyline$encodeUnsignedIntegers} from '../../ol/format/Polyline.js';
import {decodeUnsignedIntegers as _ol_format_Polyline$decodeUnsignedIntegers} from '../../ol/format/Polyline.js';
import {encodeUnsignedInteger as _ol_format_Polyline$encodeUnsignedInteger} from '../../ol/format/Polyline.js';
import $ol$format$TextFeature from '../../ol/format/TextFeature.js';
import $ol$format$TopoJSON from '../../ol/format/TopoJSON.js';
import $ol$format$WFS from '../../ol/format/WFS.js';
import {writeFilter as _ol_format_WFS$writeFilter} from '../../ol/format/WFS.js';
import $ol$format$WKB from '../../ol/format/WKB.js';
import $ol$format$WKT from '../../ol/format/WKT.js';
import $ol$format$WMSCapabilities from '../../ol/format/WMSCapabilities.js';
import $ol$format$WMSGetFeatureInfo from '../../ol/format/WMSGetFeatureInfo.js';
import $ol$format$WMTSCapabilities from '../../ol/format/WMTSCapabilities.js';
import $ol$format$XML from '../../ol/format/XML.js';
import $ol$format$XMLFeature from '../../ol/format/XMLFeature.js';
import {and as _ol_format_filter$and} from '../../ol/format/filter.js';
import {or as _ol_format_filter$or} from '../../ol/format/filter.js';
import {not as _ol_format_filter$not} from '../../ol/format/filter.js';
import {bbox as _ol_format_filter$bbox} from '../../ol/format/filter.js';
import {contains as _ol_format_filter$contains} from '../../ol/format/filter.js';
import {intersects as _ol_format_filter$intersects} from '../../ol/format/filter.js';
import {disjoint as _ol_format_filter$disjoint} from '../../ol/format/filter.js';
import {within as _ol_format_filter$within} from '../../ol/format/filter.js';
import {dwithin as _ol_format_filter$dwithin} from '../../ol/format/filter.js';
import {equalTo as _ol_format_filter$equalTo} from '../../ol/format/filter.js';
import {notEqualTo as _ol_format_filter$notEqualTo} from '../../ol/format/filter.js';
import {lessThan as _ol_format_filter$lessThan} from '../../ol/format/filter.js';
import {lessThanOrEqualTo as _ol_format_filter$lessThanOrEqualTo} from '../../ol/format/filter.js';
import {greaterThan as _ol_format_filter$greaterThan} from '../../ol/format/filter.js';
import {greaterThanOrEqualTo as _ol_format_filter$greaterThanOrEqualTo} from '../../ol/format/filter.js';
import {isNull as _ol_format_filter$isNull} from '../../ol/format/filter.js';
import {between as _ol_format_filter$between} from '../../ol/format/filter.js';
import {like as _ol_format_filter$like} from '../../ol/format/filter.js';
import {during as _ol_format_filter$during} from '../../ol/format/filter.js';
import {resourceId as _ol_format_filter$resourceId} from '../../ol/format/filter.js';
import {readHref as _ol_format_xlink$readHref} from '../../ol/format/xlink.js';
import {readBoolean as _ol_format_xsd$readBoolean} from '../../ol/format/xsd.js';
import {readBooleanString as _ol_format_xsd$readBooleanString} from '../../ol/format/xsd.js';
import {readDateTime as _ol_format_xsd$readDateTime} from '../../ol/format/xsd.js';
import {readDecimal as _ol_format_xsd$readDecimal} from '../../ol/format/xsd.js';
import {readDecimalString as _ol_format_xsd$readDecimalString} from '../../ol/format/xsd.js';
import {readPositiveInteger as _ol_format_xsd$readPositiveInteger} from '../../ol/format/xsd.js';
import {readNonNegativeIntegerString as _ol_format_xsd$readNonNegativeIntegerString} from '../../ol/format/xsd.js';
import {readString as _ol_format_xsd$readString} from '../../ol/format/xsd.js';
import {writeBooleanTextNode as _ol_format_xsd$writeBooleanTextNode} from '../../ol/format/xsd.js';
import {writeCDATASection as _ol_format_xsd$writeCDATASection} from '../../ol/format/xsd.js';
import {writeDateTimeTextNode as _ol_format_xsd$writeDateTimeTextNode} from '../../ol/format/xsd.js';
import {writeDecimalTextNode as _ol_format_xsd$writeDecimalTextNode} from '../../ol/format/xsd.js';
import {writeNonNegativeIntegerTextNode as _ol_format_xsd$writeNonNegativeIntegerTextNode} from '../../ol/format/xsd.js';
import {writeStringTextNode as _ol_format_xsd$writeStringTextNode} from '../../ol/format/xsd.js';
import $ol$format$filter$And from '../../ol/format/filter/And.js';
import $ol$format$filter$Bbox from '../../ol/format/filter/Bbox.js';
import $ol$format$filter$Comparison from '../../ol/format/filter/Comparison.js';
import $ol$format$filter$ComparisonBinary from '../../ol/format/filter/ComparisonBinary.js';
import $ol$format$filter$Contains from '../../ol/format/filter/Contains.js';
import $ol$format$filter$DWithin from '../../ol/format/filter/DWithin.js';
import $ol$format$filter$Disjoint from '../../ol/format/filter/Disjoint.js';
import $ol$format$filter$During from '../../ol/format/filter/During.js';
import $ol$format$filter$EqualTo from '../../ol/format/filter/EqualTo.js';
import $ol$format$filter$Filter from '../../ol/format/filter/Filter.js';
import $ol$format$filter$GreaterThan from '../../ol/format/filter/GreaterThan.js';
import $ol$format$filter$GreaterThanOrEqualTo from '../../ol/format/filter/GreaterThanOrEqualTo.js';
import $ol$format$filter$Intersects from '../../ol/format/filter/Intersects.js';
import $ol$format$filter$IsBetween from '../../ol/format/filter/IsBetween.js';
import $ol$format$filter$IsLike from '../../ol/format/filter/IsLike.js';
import $ol$format$filter$IsNull from '../../ol/format/filter/IsNull.js';
import $ol$format$filter$LessThan from '../../ol/format/filter/LessThan.js';
import $ol$format$filter$LessThanOrEqualTo from '../../ol/format/filter/LessThanOrEqualTo.js';
import $ol$format$filter$LogicalNary from '../../ol/format/filter/LogicalNary.js';
import $ol$format$filter$Not from '../../ol/format/filter/Not.js';
import $ol$format$filter$NotEqualTo from '../../ol/format/filter/NotEqualTo.js';
import $ol$format$filter$Or from '../../ol/format/filter/Or.js';
import $ol$format$filter$ResourceId from '../../ol/format/filter/ResourceId.js';
import $ol$format$filter$Spatial from '../../ol/format/filter/Spatial.js';
import $ol$format$filter$Within from '../../ol/format/filter/Within.js';
import {newEvaluationContext as _ol_expr_cpu$newEvaluationContext} from '../../ol/expr/cpu.js';
import {buildExpression as _ol_expr_cpu$buildExpression} from '../../ol/expr/cpu.js';
import {NoneType as _ol_expr_expression$NoneType} from '../../ol/expr/expression.js';
import {BooleanType as _ol_expr_expression$BooleanType} from '../../ol/expr/expression.js';
import {NumberType as _ol_expr_expression$NumberType} from '../../ol/expr/expression.js';
import {StringType as _ol_expr_expression$StringType} from '../../ol/expr/expression.js';
import {ColorType as _ol_expr_expression$ColorType} from '../../ol/expr/expression.js';
import {NumberArrayType as _ol_expr_expression$NumberArrayType} from '../../ol/expr/expression.js';
import {SizeType as _ol_expr_expression$SizeType} from '../../ol/expr/expression.js';
import {AnyType as _ol_expr_expression$AnyType} from '../../ol/expr/expression.js';
import {typeName as _ol_expr_expression$typeName} from '../../ol/expr/expression.js';
import {includesType as _ol_expr_expression$includesType} from '../../ol/expr/expression.js';
import {overlapsType as _ol_expr_expression$overlapsType} from '../../ol/expr/expression.js';
import {isType as _ol_expr_expression$isType} from '../../ol/expr/expression.js';
import {LiteralExpression as _ol_expr_expression$LiteralExpression} from '../../ol/expr/expression.js';
import {CallExpression as _ol_expr_expression$CallExpression} from '../../ol/expr/expression.js';
import {newParsingContext as _ol_expr_expression$newParsingContext} from '../../ol/expr/expression.js';
import {parse as _ol_expr_expression$parse} from '../../ol/expr/expression.js';
import {Ops as _ol_expr_expression$Ops} from '../../ol/expr/expression.js';
import {computeGeometryType as _ol_expr_expression$computeGeometryType} from '../../ol/expr/expression.js';
import {numberToGlsl as _ol_expr_gpu$numberToGlsl} from '../../ol/expr/gpu.js';
import {arrayToGlsl as _ol_expr_gpu$arrayToGlsl} from '../../ol/expr/gpu.js';
import {colorToGlsl as _ol_expr_gpu$colorToGlsl} from '../../ol/expr/gpu.js';
import {sizeToGlsl as _ol_expr_gpu$sizeToGlsl} from '../../ol/expr/gpu.js';
import {getStringNumberEquivalent as _ol_expr_gpu$getStringNumberEquivalent} from '../../ol/expr/gpu.js';
import {stringToGlsl as _ol_expr_gpu$stringToGlsl} from '../../ol/expr/gpu.js';
import {uniformNameForVariable as _ol_expr_gpu$uniformNameForVariable} from '../../ol/expr/gpu.js';
import {newCompilationContext as _ol_expr_gpu$newCompilationContext} from '../../ol/expr/gpu.js';
import {PALETTE_TEXTURE_ARRAY as _ol_expr_gpu$PALETTE_TEXTURE_ARRAY} from '../../ol/expr/gpu.js';
import {buildExpression as _ol_expr_gpu$buildExpression} from '../../ol/expr/gpu.js';
import $ol$events$Event from '../../ol/events/Event.js';
import {stopPropagation as _ol_events_Event$stopPropagation} from '../../ol/events/Event.js';
import {preventDefault as _ol_events_Event$preventDefault} from '../../ol/events/Event.js';
import {SnapEvent as _ol_events_SnapEvent$SnapEvent} from '../../ol/events/SnapEvent.js';
import $ol$events$Target from '../../ol/events/Target.js';
import {all as _ol_events_condition$all} from '../../ol/events/condition.js';
import {altKeyOnly as _ol_events_condition$altKeyOnly} from '../../ol/events/condition.js';
import {altShiftKeysOnly as _ol_events_condition$altShiftKeysOnly} from '../../ol/events/condition.js';
import {focus as _ol_events_condition$focus} from '../../ol/events/condition.js';
import {focusWithTabindex as _ol_events_condition$focusWithTabindex} from '../../ol/events/condition.js';
import {always as _ol_events_condition$always} from '../../ol/events/condition.js';
import {click as _ol_events_condition$click} from '../../ol/events/condition.js';
import {mouseActionButton as _ol_events_condition$mouseActionButton} from '../../ol/events/condition.js';
import {never as _ol_events_condition$never} from '../../ol/events/condition.js';
import {pointerMove as _ol_events_condition$pointerMove} from '../../ol/events/condition.js';
import {singleClick as _ol_events_condition$singleClick} from '../../ol/events/condition.js';
import {doubleClick as _ol_events_condition$doubleClick} from '../../ol/events/condition.js';
import {noModifierKeys as _ol_events_condition$noModifierKeys} from '../../ol/events/condition.js';
import {platformModifierKeyOnly as _ol_events_condition$platformModifierKeyOnly} from '../../ol/events/condition.js';
import {platformModifierKey as _ol_events_condition$platformModifierKey} from '../../ol/events/condition.js';
import {shiftKeyOnly as _ol_events_condition$shiftKeyOnly} from '../../ol/events/condition.js';
import {targetNotEditable as _ol_events_condition$targetNotEditable} from '../../ol/events/condition.js';
import {mouseOnly as _ol_events_condition$mouseOnly} from '../../ol/events/condition.js';
import {touchOnly as _ol_events_condition$touchOnly} from '../../ol/events/condition.js';
import {penOnly as _ol_events_condition$penOnly} from '../../ol/events/condition.js';
import {primaryAction as _ol_events_condition$primaryAction} from '../../ol/events/condition.js';
import $ol$control$Attribution from '../../ol/control/Attribution.js';
import $ol$control$Control from '../../ol/control/Control.js';
import $ol$control$FullScreen from '../../ol/control/FullScreen.js';
import $ol$control$MousePosition from '../../ol/control/MousePosition.js';
import $ol$control$OverviewMap from '../../ol/control/OverviewMap.js';
import $ol$control$Rotate from '../../ol/control/Rotate.js';
import $ol$control$ScaleLine from '../../ol/control/ScaleLine.js';
import $ol$control$Zoom from '../../ol/control/Zoom.js';
import $ol$control$ZoomSlider from '../../ol/control/ZoomSlider.js';
import $ol$control$ZoomToExtent from '../../ol/control/ZoomToExtent.js';
import {defaults as _ol_control_defaults$defaults} from '../../ol/control/defaults.js';

var ol = {};
ol.Collection = $ol$Collection;
ol.Collection.CollectionEvent = _ol_Collection$CollectionEvent;
ol.DataTile = $ol$DataTile;
ol.DataTile.asArrayLike = _ol_DataTile$asArrayLike;
ol.DataTile.asImageLike = _ol_DataTile$asImageLike;
ol.DataTile.toArray = _ol_DataTile$toArray;
ol.Disposable = $ol$Disposable;
ol.Feature = $ol$Feature;
ol.Feature.createStyleFunction = _ol_Feature$createStyleFunction;
ol.Geolocation = $ol$Geolocation;
ol.Geolocation.GeolocationError = _ol_Geolocation$GeolocationError;
ol.Image = $ol$Image;
ol.Image.decode = _ol_Image$decode;
ol.Image.decodeFallback = _ol_Image$decodeFallback;
ol.Image.listenImage = _ol_Image$listenImage;
ol.Image.load = _ol_Image$load;
ol.ImageCanvas = $ol$ImageCanvas;
ol.ImageTile = $ol$ImageTile;
ol.Kinetic = $ol$Kinetic;
ol.Map = $ol$Map;
ol.MapBrowserEvent = $ol$MapBrowserEvent;
ol.MapBrowserEventHandler = $ol$MapBrowserEventHandler;
ol.MapEvent = $ol$MapEvent;
ol.Object = $ol$Object;
ol.Object.ObjectEvent = _ol_Object$ObjectEvent;
ol.Observable = $ol$Observable;
ol.Observable.unByKey = _ol_Observable$unByKey;
ol.Overlay = $ol$Overlay;
ol.Tile = $ol$Tile;
ol.TileCache = $ol$TileCache;
ol.TileQueue = $ol$TileQueue;
ol.TileQueue.getTilePriority = _ol_TileQueue$getTilePriority;
ol.TileRange = $ol$TileRange;
ol.TileRange.createOrUpdate = _ol_TileRange$createOrUpdate;
ol.VectorRenderTile = $ol$VectorRenderTile;
ol.VectorTile = $ol$VectorTile;
ol.View = $ol$View;
ol.View.createCenterConstraint = _ol_View$createCenterConstraint;
ol.View.createResolutionConstraint = _ol_View$createResolutionConstraint;
ol.View.createRotationConstraint = _ol_View$createRotationConstraint;
ol.View.isNoopAnimation = _ol_View$isNoopAnimation;
ol.array = {};
ol.array.ascending = _ol_array$ascending;
ol.array.binarySearch = _ol_array$binarySearch;
ol.array.descending = _ol_array$descending;
ol.array.equals = _ol_array$equals;
ol.array.extend = _ol_array$extend;
ol.array.isSorted = _ol_array$isSorted;
ol.array.linearFindNearest = _ol_array$linearFindNearest;
ol.array.remove = _ol_array$remove;
ol.array.reverseSubArray = _ol_array$reverseSubArray;
ol.array.stableSort = _ol_array$stableSort;
ol.asserts = {};
ol.asserts.assert = _ol_asserts$assert;
ol.centerconstraint = {};
ol.centerconstraint.createExtent = _ol_centerconstraint$createExtent;
ol.centerconstraint.none = _ol_centerconstraint$none;
ol.color = {};
ol.color.asArray = _ol_color$asArray;
ol.color.asString = _ol_color$asString;
ol.color.fromString = _ol_color$fromString;
ol.color.isStringColor = _ol_color$isStringColor;
ol.color.lchaToRgba = _ol_color$lchaToRgba;
ol.color.normalize = _ol_color$normalize;
ol.color.rgbaToLcha = _ol_color$rgbaToLcha;
ol.color.toString = _ol_color$toString;
ol.color.withAlpha = _ol_color$withAlpha;
ol.colorlike = {};
ol.colorlike.asColorLike = _ol_colorlike$asColorLike;
ol.console = {};
ol.console.error = _ol_console$error;
ol.console.log = _ol_console$log;
ol.console.setLevel = _ol_console$setLevel;
ol.console.warn = _ol_console$warn;
ol.control = {};
ol.control.Attribution = $ol$control$Attribution;
ol.control.Control = $ol$control$Control;
ol.control.FullScreen = $ol$control$FullScreen;
ol.control.MousePosition = $ol$control$MousePosition;
ol.control.OverviewMap = $ol$control$OverviewMap;
ol.control.Rotate = $ol$control$Rotate;
ol.control.ScaleLine = $ol$control$ScaleLine;
ol.control.Zoom = $ol$control$Zoom;
ol.control.ZoomSlider = $ol$control$ZoomSlider;
ol.control.ZoomToExtent = $ol$control$ZoomToExtent;
ol.control.defaults = {};
ol.control.defaults.defaults = _ol_control_defaults$defaults;
ol.coordinate = {};
ol.coordinate.add = _ol_coordinate$add;
ol.coordinate.closestOnCircle = _ol_coordinate$closestOnCircle;
ol.coordinate.closestOnSegment = _ol_coordinate$closestOnSegment;
ol.coordinate.createStringXY = _ol_coordinate$createStringXY;
ol.coordinate.degreesToStringHDMS = _ol_coordinate$degreesToStringHDMS;
ol.coordinate.distance = _ol_coordinate$distance;
ol.coordinate.equals = _ol_coordinate$equals;
ol.coordinate.format = _ol_coordinate$format;
ol.coordinate.getWorldsAway = _ol_coordinate$getWorldsAway;
ol.coordinate.rotate = _ol_coordinate$rotate;
ol.coordinate.scale = _ol_coordinate$scale;
ol.coordinate.squaredDistance = _ol_coordinate$squaredDistance;
ol.coordinate.squaredDistanceToSegment = _ol_coordinate$squaredDistanceToSegment;
ol.coordinate.toStringHDMS = _ol_coordinate$toStringHDMS;
ol.coordinate.toStringXY = _ol_coordinate$toStringXY;
ol.coordinate.wrapX = _ol_coordinate$wrapX;
ol.css = {};
ol.css.CLASS_COLLAPSED = _ol_css$CLASS_COLLAPSED;
ol.css.CLASS_CONTROL = _ol_css$CLASS_CONTROL;
ol.css.CLASS_HIDDEN = _ol_css$CLASS_HIDDEN;
ol.css.CLASS_SELECTABLE = _ol_css$CLASS_SELECTABLE;
ol.css.CLASS_UNSELECTABLE = _ol_css$CLASS_UNSELECTABLE;
ol.css.CLASS_UNSUPPORTED = _ol_css$CLASS_UNSUPPORTED;
ol.css.getFontParameters = _ol_css$getFontParameters;
ol.dom = {};
ol.dom.createCanvasContext2D = _ol_dom$createCanvasContext2D;
ol.dom.getSharedCanvasContext2D = _ol_dom$getSharedCanvasContext2D;
ol.dom.outerHeight = _ol_dom$outerHeight;
ol.dom.outerWidth = _ol_dom$outerWidth;
ol.dom.releaseCanvas = _ol_dom$releaseCanvas;
ol.dom.removeChildren = _ol_dom$removeChildren;
ol.dom.removeNode = _ol_dom$removeNode;
ol.dom.replaceChildren = _ol_dom$replaceChildren;
ol.dom.replaceNode = _ol_dom$replaceNode;
ol.easing = {};
ol.easing.easeIn = _ol_easing$easeIn;
ol.easing.easeOut = _ol_easing$easeOut;
ol.easing.inAndOut = _ol_easing$inAndOut;
ol.easing.linear = _ol_easing$linear;
ol.easing.upAndDown = _ol_easing$upAndDown;
ol.events = {};
ol.events.Event = $ol$events$Event;
ol.events.Event.preventDefault = _ol_events_Event$preventDefault;
ol.events.Event.stopPropagation = _ol_events_Event$stopPropagation;
ol.events.SnapEvent = {};
ol.events.SnapEvent.SnapEvent = _ol_events_SnapEvent$SnapEvent;
ol.events.Target = $ol$events$Target;
ol.events.condition = {};
ol.events.condition.all = _ol_events_condition$all;
ol.events.condition.altKeyOnly = _ol_events_condition$altKeyOnly;
ol.events.condition.altShiftKeysOnly = _ol_events_condition$altShiftKeysOnly;
ol.events.condition.always = _ol_events_condition$always;
ol.events.condition.click = _ol_events_condition$click;
ol.events.condition.doubleClick = _ol_events_condition$doubleClick;
ol.events.condition.focus = _ol_events_condition$focus;
ol.events.condition.focusWithTabindex = _ol_events_condition$focusWithTabindex;
ol.events.condition.mouseActionButton = _ol_events_condition$mouseActionButton;
ol.events.condition.mouseOnly = _ol_events_condition$mouseOnly;
ol.events.condition.never = _ol_events_condition$never;
ol.events.condition.noModifierKeys = _ol_events_condition$noModifierKeys;
ol.events.condition.penOnly = _ol_events_condition$penOnly;
ol.events.condition.platformModifierKey = _ol_events_condition$platformModifierKey;
ol.events.condition.platformModifierKeyOnly = _ol_events_condition$platformModifierKeyOnly;
ol.events.condition.pointerMove = _ol_events_condition$pointerMove;
ol.events.condition.primaryAction = _ol_events_condition$primaryAction;
ol.events.condition.shiftKeyOnly = _ol_events_condition$shiftKeyOnly;
ol.events.condition.singleClick = _ol_events_condition$singleClick;
ol.events.condition.targetNotEditable = _ol_events_condition$targetNotEditable;
ol.events.condition.touchOnly = _ol_events_condition$touchOnly;
ol.events.listen = _ol_events$listen;
ol.events.listenOnce = _ol_events$listenOnce;
ol.events.unlistenByKey = _ol_events$unlistenByKey;
ol.expr = {};
ol.expr.cpu = {};
ol.expr.cpu.buildExpression = _ol_expr_cpu$buildExpression;
ol.expr.cpu.newEvaluationContext = _ol_expr_cpu$newEvaluationContext;
ol.expr.expression = {};
ol.expr.expression.AnyType = _ol_expr_expression$AnyType;
ol.expr.expression.BooleanType = _ol_expr_expression$BooleanType;
ol.expr.expression.CallExpression = _ol_expr_expression$CallExpression;
ol.expr.expression.ColorType = _ol_expr_expression$ColorType;
ol.expr.expression.LiteralExpression = _ol_expr_expression$LiteralExpression;
ol.expr.expression.NoneType = _ol_expr_expression$NoneType;
ol.expr.expression.NumberArrayType = _ol_expr_expression$NumberArrayType;
ol.expr.expression.NumberType = _ol_expr_expression$NumberType;
ol.expr.expression.Ops = _ol_expr_expression$Ops;
ol.expr.expression.SizeType = _ol_expr_expression$SizeType;
ol.expr.expression.StringType = _ol_expr_expression$StringType;
ol.expr.expression.computeGeometryType = _ol_expr_expression$computeGeometryType;
ol.expr.expression.includesType = _ol_expr_expression$includesType;
ol.expr.expression.isType = _ol_expr_expression$isType;
ol.expr.expression.newParsingContext = _ol_expr_expression$newParsingContext;
ol.expr.expression.overlapsType = _ol_expr_expression$overlapsType;
ol.expr.expression.parse = _ol_expr_expression$parse;
ol.expr.expression.typeName = _ol_expr_expression$typeName;
ol.expr.gpu = {};
ol.expr.gpu.PALETTE_TEXTURE_ARRAY = _ol_expr_gpu$PALETTE_TEXTURE_ARRAY;
ol.expr.gpu.arrayToGlsl = _ol_expr_gpu$arrayToGlsl;
ol.expr.gpu.buildExpression = _ol_expr_gpu$buildExpression;
ol.expr.gpu.colorToGlsl = _ol_expr_gpu$colorToGlsl;
ol.expr.gpu.getStringNumberEquivalent = _ol_expr_gpu$getStringNumberEquivalent;
ol.expr.gpu.newCompilationContext = _ol_expr_gpu$newCompilationContext;
ol.expr.gpu.numberToGlsl = _ol_expr_gpu$numberToGlsl;
ol.expr.gpu.sizeToGlsl = _ol_expr_gpu$sizeToGlsl;
ol.expr.gpu.stringToGlsl = _ol_expr_gpu$stringToGlsl;
ol.expr.gpu.uniformNameForVariable = _ol_expr_gpu$uniformNameForVariable;
ol.extent = {};
ol.extent.applyTransform = _ol_extent$applyTransform;
ol.extent.approximatelyEquals = _ol_extent$approximatelyEquals;
ol.extent.boundingExtent = _ol_extent$boundingExtent;
ol.extent.buffer = _ol_extent$buffer;
ol.extent.clone = _ol_extent$clone;
ol.extent.closestSquaredDistanceXY = _ol_extent$closestSquaredDistanceXY;
ol.extent.containsCoordinate = _ol_extent$containsCoordinate;
ol.extent.containsExtent = _ol_extent$containsExtent;
ol.extent.containsXY = _ol_extent$containsXY;
ol.extent.coordinateRelationship = _ol_extent$coordinateRelationship;
ol.extent.createEmpty = _ol_extent$createEmpty;
ol.extent.createOrUpdate = _ol_extent$createOrUpdate;
ol.extent.createOrUpdateEmpty = _ol_extent$createOrUpdateEmpty;
ol.extent.createOrUpdateFromCoordinate = _ol_extent$createOrUpdateFromCoordinate;
ol.extent.createOrUpdateFromCoordinates = _ol_extent$createOrUpdateFromCoordinates;
ol.extent.createOrUpdateFromFlatCoordinates = _ol_extent$createOrUpdateFromFlatCoordinates;
ol.extent.createOrUpdateFromRings = _ol_extent$createOrUpdateFromRings;
ol.extent.equals = _ol_extent$equals;
ol.extent.extend = _ol_extent$extend;
ol.extent.extendCoordinate = _ol_extent$extendCoordinate;
ol.extent.extendCoordinates = _ol_extent$extendCoordinates;
ol.extent.extendFlatCoordinates = _ol_extent$extendFlatCoordinates;
ol.extent.extendRings = _ol_extent$extendRings;
ol.extent.extendXY = _ol_extent$extendXY;
ol.extent.forEachCorner = _ol_extent$forEachCorner;
ol.extent.getArea = _ol_extent$getArea;
ol.extent.getBottomLeft = _ol_extent$getBottomLeft;
ol.extent.getBottomRight = _ol_extent$getBottomRight;
ol.extent.getCenter = _ol_extent$getCenter;
ol.extent.getCorner = _ol_extent$getCorner;
ol.extent.getEnlargedArea = _ol_extent$getEnlargedArea;
ol.extent.getForViewAndSize = _ol_extent$getForViewAndSize;
ol.extent.getHeight = _ol_extent$getHeight;
ol.extent.getIntersection = _ol_extent$getIntersection;
ol.extent.getIntersectionArea = _ol_extent$getIntersectionArea;
ol.extent.getMargin = _ol_extent$getMargin;
ol.extent.getRotatedViewport = _ol_extent$getRotatedViewport;
ol.extent.getSize = _ol_extent$getSize;
ol.extent.getTopLeft = _ol_extent$getTopLeft;
ol.extent.getTopRight = _ol_extent$getTopRight;
ol.extent.getWidth = _ol_extent$getWidth;
ol.extent.intersects = _ol_extent$intersects;
ol.extent.intersectsSegment = _ol_extent$intersectsSegment;
ol.extent.isEmpty = _ol_extent$isEmpty;
ol.extent.returnOrUpdate = _ol_extent$returnOrUpdate;
ol.extent.scaleFromCenter = _ol_extent$scaleFromCenter;
ol.extent.wrapAndSliceX = _ol_extent$wrapAndSliceX;
ol.extent.wrapX = _ol_extent$wrapX;
ol.featureloader = {};
ol.featureloader.loadFeaturesXhr = _ol_featureloader$loadFeaturesXhr;
ol.featureloader.setWithCredentials = _ol_featureloader$setWithCredentials;
ol.featureloader.xhr = _ol_featureloader$xhr;
ol.format = {};
ol.format.EsriJSON = $ol$format$EsriJSON;
ol.format.Feature = $ol$format$Feature;
ol.format.Feature.createGeometry = _ol_format_Feature$createGeometry;
ol.format.Feature.createRenderFeature = _ol_format_Feature$createRenderFeature;
ol.format.Feature.transformExtentWithOptions = _ol_format_Feature$transformExtentWithOptions;
ol.format.Feature.transformGeometryWithOptions = _ol_format_Feature$transformGeometryWithOptions;
ol.format.GML = $ol$format$GML;
ol.format.GML2 = $ol$format$GML2;
ol.format.GML3 = $ol$format$GML3;
ol.format.GML32 = $ol$format$GML32;
ol.format.GMLBase = $ol$format$GMLBase;
ol.format.GMLBase.GMLNS = _ol_format_GMLBase$GMLNS;
ol.format.GPX = $ol$format$GPX;
ol.format.GeoJSON = $ol$format$GeoJSON;
ol.format.IGC = $ol$format$IGC;
ol.format.IIIFInfo = $ol$format$IIIFInfo;
ol.format.JSONFeature = $ol$format$JSONFeature;
ol.format.KML = $ol$format$KML;
ol.format.KML.getDefaultFillStyle = _ol_format_KML$getDefaultFillStyle;
ol.format.KML.getDefaultImageStyle = _ol_format_KML$getDefaultImageStyle;
ol.format.KML.getDefaultStrokeStyle = _ol_format_KML$getDefaultStrokeStyle;
ol.format.KML.getDefaultStyle = _ol_format_KML$getDefaultStyle;
ol.format.KML.getDefaultStyleArray = _ol_format_KML$getDefaultStyleArray;
ol.format.KML.getDefaultTextStyle = _ol_format_KML$getDefaultTextStyle;
ol.format.KML.readFlatCoordinates = _ol_format_KML$readFlatCoordinates;
ol.format.MVT = $ol$format$MVT;
ol.format.OSMXML = $ol$format$OSMXML;
ol.format.OWS = $ol$format$OWS;
ol.format.Polyline = $ol$format$Polyline;
ol.format.Polyline.decodeDeltas = _ol_format_Polyline$decodeDeltas;
ol.format.Polyline.decodeFloats = _ol_format_Polyline$decodeFloats;
ol.format.Polyline.decodeSignedIntegers = _ol_format_Polyline$decodeSignedIntegers;
ol.format.Polyline.decodeUnsignedIntegers = _ol_format_Polyline$decodeUnsignedIntegers;
ol.format.Polyline.encodeDeltas = _ol_format_Polyline$encodeDeltas;
ol.format.Polyline.encodeFloats = _ol_format_Polyline$encodeFloats;
ol.format.Polyline.encodeSignedIntegers = _ol_format_Polyline$encodeSignedIntegers;
ol.format.Polyline.encodeUnsignedInteger = _ol_format_Polyline$encodeUnsignedInteger;
ol.format.Polyline.encodeUnsignedIntegers = _ol_format_Polyline$encodeUnsignedIntegers;
ol.format.TextFeature = $ol$format$TextFeature;
ol.format.TopoJSON = $ol$format$TopoJSON;
ol.format.WFS = $ol$format$WFS;
ol.format.WFS.writeFilter = _ol_format_WFS$writeFilter;
ol.format.WKB = $ol$format$WKB;
ol.format.WKT = $ol$format$WKT;
ol.format.WMSCapabilities = $ol$format$WMSCapabilities;
ol.format.WMSGetFeatureInfo = $ol$format$WMSGetFeatureInfo;
ol.format.WMTSCapabilities = $ol$format$WMTSCapabilities;
ol.format.XML = $ol$format$XML;
ol.format.XMLFeature = $ol$format$XMLFeature;
ol.format.filter = {};
ol.format.filter.And = $ol$format$filter$And;
ol.format.filter.Bbox = $ol$format$filter$Bbox;
ol.format.filter.Comparison = $ol$format$filter$Comparison;
ol.format.filter.ComparisonBinary = $ol$format$filter$ComparisonBinary;
ol.format.filter.Contains = $ol$format$filter$Contains;
ol.format.filter.DWithin = $ol$format$filter$DWithin;
ol.format.filter.Disjoint = $ol$format$filter$Disjoint;
ol.format.filter.During = $ol$format$filter$During;
ol.format.filter.EqualTo = $ol$format$filter$EqualTo;
ol.format.filter.Filter = $ol$format$filter$Filter;
ol.format.filter.GreaterThan = $ol$format$filter$GreaterThan;
ol.format.filter.GreaterThanOrEqualTo = $ol$format$filter$GreaterThanOrEqualTo;
ol.format.filter.Intersects = $ol$format$filter$Intersects;
ol.format.filter.IsBetween = $ol$format$filter$IsBetween;
ol.format.filter.IsLike = $ol$format$filter$IsLike;
ol.format.filter.IsNull = $ol$format$filter$IsNull;
ol.format.filter.LessThan = $ol$format$filter$LessThan;
ol.format.filter.LessThanOrEqualTo = $ol$format$filter$LessThanOrEqualTo;
ol.format.filter.LogicalNary = $ol$format$filter$LogicalNary;
ol.format.filter.Not = $ol$format$filter$Not;
ol.format.filter.NotEqualTo = $ol$format$filter$NotEqualTo;
ol.format.filter.Or = $ol$format$filter$Or;
ol.format.filter.ResourceId = $ol$format$filter$ResourceId;
ol.format.filter.Spatial = $ol$format$filter$Spatial;
ol.format.filter.Within = $ol$format$filter$Within;
ol.format.filter.and = _ol_format_filter$and;
ol.format.filter.bbox = _ol_format_filter$bbox;
ol.format.filter.between = _ol_format_filter$between;
ol.format.filter.contains = _ol_format_filter$contains;
ol.format.filter.disjoint = _ol_format_filter$disjoint;
ol.format.filter.during = _ol_format_filter$during;
ol.format.filter.dwithin = _ol_format_filter$dwithin;
ol.format.filter.equalTo = _ol_format_filter$equalTo;
ol.format.filter.greaterThan = _ol_format_filter$greaterThan;
ol.format.filter.greaterThanOrEqualTo = _ol_format_filter$greaterThanOrEqualTo;
ol.format.filter.intersects = _ol_format_filter$intersects;
ol.format.filter.isNull = _ol_format_filter$isNull;
ol.format.filter.lessThan = _ol_format_filter$lessThan;
ol.format.filter.lessThanOrEqualTo = _ol_format_filter$lessThanOrEqualTo;
ol.format.filter.like = _ol_format_filter$like;
ol.format.filter.not = _ol_format_filter$not;
ol.format.filter.notEqualTo = _ol_format_filter$notEqualTo;
ol.format.filter.or = _ol_format_filter$or;
ol.format.filter.resourceId = _ol_format_filter$resourceId;
ol.format.filter.within = _ol_format_filter$within;
ol.format.xlink = {};
ol.format.xlink.readHref = _ol_format_xlink$readHref;
ol.format.xsd = {};
ol.format.xsd.readBoolean = _ol_format_xsd$readBoolean;
ol.format.xsd.readBooleanString = _ol_format_xsd$readBooleanString;
ol.format.xsd.readDateTime = _ol_format_xsd$readDateTime;
ol.format.xsd.readDecimal = _ol_format_xsd$readDecimal;
ol.format.xsd.readDecimalString = _ol_format_xsd$readDecimalString;
ol.format.xsd.readNonNegativeIntegerString = _ol_format_xsd$readNonNegativeIntegerString;
ol.format.xsd.readPositiveInteger = _ol_format_xsd$readPositiveInteger;
ol.format.xsd.readString = _ol_format_xsd$readString;
ol.format.xsd.writeBooleanTextNode = _ol_format_xsd$writeBooleanTextNode;
ol.format.xsd.writeCDATASection = _ol_format_xsd$writeCDATASection;
ol.format.xsd.writeDateTimeTextNode = _ol_format_xsd$writeDateTimeTextNode;
ol.format.xsd.writeDecimalTextNode = _ol_format_xsd$writeDecimalTextNode;
ol.format.xsd.writeNonNegativeIntegerTextNode = _ol_format_xsd$writeNonNegativeIntegerTextNode;
ol.format.xsd.writeStringTextNode = _ol_format_xsd$writeStringTextNode;
ol.functions = {};
ol.functions.FALSE = _ol_functions$FALSE;
ol.functions.TRUE = _ol_functions$TRUE;
ol.functions.VOID = _ol_functions$VOID;
ol.functions.memoizeOne = _ol_functions$memoizeOne;
ol.functions.toPromise = _ol_functions$toPromise;
ol.geom = {};
ol.geom.Circle = $ol$geom$Circle;
ol.geom.Geometry = $ol$geom$Geometry;
ol.geom.GeometryCollection = $ol$geom$GeometryCollection;
ol.geom.LineString = $ol$geom$LineString;
ol.geom.LinearRing = $ol$geom$LinearRing;
ol.geom.MultiLineString = $ol$geom$MultiLineString;
ol.geom.MultiPoint = $ol$geom$MultiPoint;
ol.geom.MultiPolygon = $ol$geom$MultiPolygon;
ol.geom.Point = $ol$geom$Point;
ol.geom.Polygon = $ol$geom$Polygon;
ol.geom.Polygon.circular = _ol_geom_Polygon$circular;
ol.geom.Polygon.fromCircle = _ol_geom_Polygon$fromCircle;
ol.geom.Polygon.fromExtent = _ol_geom_Polygon$fromExtent;
ol.geom.Polygon.makeRegular = _ol_geom_Polygon$makeRegular;
ol.geom.SimpleGeometry = $ol$geom$SimpleGeometry;
ol.geom.SimpleGeometry.getLayoutForStride = _ol_geom_SimpleGeometry$getLayoutForStride;
ol.geom.SimpleGeometry.getStrideForLayout = _ol_geom_SimpleGeometry$getStrideForLayout;
ol.geom.SimpleGeometry.transformGeom2D = _ol_geom_SimpleGeometry$transformGeom2D;
ol.geom.flat = {};
ol.geom.flat.area = {};
ol.geom.flat.area.linearRing = _ol_geom_flat_area$linearRing;
ol.geom.flat.area.linearRings = _ol_geom_flat_area$linearRings;
ol.geom.flat.area.linearRingss = _ol_geom_flat_area$linearRingss;
ol.geom.flat.center = {};
ol.geom.flat.center.linearRingss = _ol_geom_flat_center$linearRingss;
ol.geom.flat.closest = {};
ol.geom.flat.closest.arrayMaxSquaredDelta = _ol_geom_flat_closest$arrayMaxSquaredDelta;
ol.geom.flat.closest.assignClosestArrayPoint = _ol_geom_flat_closest$assignClosestArrayPoint;
ol.geom.flat.closest.assignClosestMultiArrayPoint = _ol_geom_flat_closest$assignClosestMultiArrayPoint;
ol.geom.flat.closest.assignClosestPoint = _ol_geom_flat_closest$assignClosestPoint;
ol.geom.flat.closest.maxSquaredDelta = _ol_geom_flat_closest$maxSquaredDelta;
ol.geom.flat.closest.multiArrayMaxSquaredDelta = _ol_geom_flat_closest$multiArrayMaxSquaredDelta;
ol.geom.flat.contains = {};
ol.geom.flat.contains.linearRingContainsExtent = _ol_geom_flat_contains$linearRingContainsExtent;
ol.geom.flat.contains.linearRingContainsXY = _ol_geom_flat_contains$linearRingContainsXY;
ol.geom.flat.contains.linearRingsContainsXY = _ol_geom_flat_contains$linearRingsContainsXY;
ol.geom.flat.contains.linearRingssContainsXY = _ol_geom_flat_contains$linearRingssContainsXY;
ol.geom.flat.deflate = {};
ol.geom.flat.deflate.deflateCoordinate = _ol_geom_flat_deflate$deflateCoordinate;
ol.geom.flat.deflate.deflateCoordinates = _ol_geom_flat_deflate$deflateCoordinates;
ol.geom.flat.deflate.deflateCoordinatesArray = _ol_geom_flat_deflate$deflateCoordinatesArray;
ol.geom.flat.deflate.deflateMultiCoordinatesArray = _ol_geom_flat_deflate$deflateMultiCoordinatesArray;
ol.geom.flat.flip = {};
ol.geom.flat.flip.flipXY = _ol_geom_flat_flip$flipXY;
ol.geom.flat.geodesic = {};
ol.geom.flat.geodesic.greatCircleArc = _ol_geom_flat_geodesic$greatCircleArc;
ol.geom.flat.geodesic.meridian = _ol_geom_flat_geodesic$meridian;
ol.geom.flat.geodesic.parallel = _ol_geom_flat_geodesic$parallel;
ol.geom.flat.inflate = {};
ol.geom.flat.inflate.inflateCoordinates = _ol_geom_flat_inflate$inflateCoordinates;
ol.geom.flat.inflate.inflateCoordinatesArray = _ol_geom_flat_inflate$inflateCoordinatesArray;
ol.geom.flat.inflate.inflateMultiCoordinatesArray = _ol_geom_flat_inflate$inflateMultiCoordinatesArray;
ol.geom.flat.interiorpoint = {};
ol.geom.flat.interiorpoint.getInteriorPointOfArray = _ol_geom_flat_interiorpoint$getInteriorPointOfArray;
ol.geom.flat.interiorpoint.getInteriorPointsOfMultiArray = _ol_geom_flat_interiorpoint$getInteriorPointsOfMultiArray;
ol.geom.flat.interpolate = {};
ol.geom.flat.interpolate.interpolatePoint = _ol_geom_flat_interpolate$interpolatePoint;
ol.geom.flat.interpolate.lineStringCoordinateAtM = _ol_geom_flat_interpolate$lineStringCoordinateAtM;
ol.geom.flat.interpolate.lineStringsCoordinateAtM = _ol_geom_flat_interpolate$lineStringsCoordinateAtM;
ol.geom.flat.intersectsextent = {};
ol.geom.flat.intersectsextent.intersectsLineString = _ol_geom_flat_intersectsextent$intersectsLineString;
ol.geom.flat.intersectsextent.intersectsLineStringArray = _ol_geom_flat_intersectsextent$intersectsLineStringArray;
ol.geom.flat.intersectsextent.intersectsLinearRing = _ol_geom_flat_intersectsextent$intersectsLinearRing;
ol.geom.flat.intersectsextent.intersectsLinearRingArray = _ol_geom_flat_intersectsextent$intersectsLinearRingArray;
ol.geom.flat.intersectsextent.intersectsLinearRingMultiArray = _ol_geom_flat_intersectsextent$intersectsLinearRingMultiArray;
ol.geom.flat.length = {};
ol.geom.flat.length.lineStringLength = _ol_geom_flat_length$lineStringLength;
ol.geom.flat.length.linearRingLength = _ol_geom_flat_length$linearRingLength;
ol.geom.flat.orient = {};
ol.geom.flat.orient.inflateEnds = _ol_geom_flat_orient$inflateEnds;
ol.geom.flat.orient.linearRingIsClockwise = _ol_geom_flat_orient$linearRingIsClockwise;
ol.geom.flat.orient.linearRingsAreOriented = _ol_geom_flat_orient$linearRingsAreOriented;
ol.geom.flat.orient.linearRingssAreOriented = _ol_geom_flat_orient$linearRingssAreOriented;
ol.geom.flat.orient.orientLinearRings = _ol_geom_flat_orient$orientLinearRings;
ol.geom.flat.orient.orientLinearRingsArray = _ol_geom_flat_orient$orientLinearRingsArray;
ol.geom.flat.reverse = {};
ol.geom.flat.reverse.coordinates = _ol_geom_flat_reverse$coordinates;
ol.geom.flat.segments = {};
ol.geom.flat.segments.forEach = _ol_geom_flat_segments$forEach;
ol.geom.flat.simplify = {};
ol.geom.flat.simplify.douglasPeucker = _ol_geom_flat_simplify$douglasPeucker;
ol.geom.flat.simplify.douglasPeuckerArray = _ol_geom_flat_simplify$douglasPeuckerArray;
ol.geom.flat.simplify.douglasPeuckerMultiArray = _ol_geom_flat_simplify$douglasPeuckerMultiArray;
ol.geom.flat.simplify.quantize = _ol_geom_flat_simplify$quantize;
ol.geom.flat.simplify.quantizeArray = _ol_geom_flat_simplify$quantizeArray;
ol.geom.flat.simplify.quantizeMultiArray = _ol_geom_flat_simplify$quantizeMultiArray;
ol.geom.flat.simplify.radialDistance = _ol_geom_flat_simplify$radialDistance;
ol.geom.flat.simplify.simplifyLineString = _ol_geom_flat_simplify$simplifyLineString;
ol.geom.flat.simplify.snap = _ol_geom_flat_simplify$snap;
ol.geom.flat.straightchunk = {};
ol.geom.flat.straightchunk.matchingChunk = _ol_geom_flat_straightchunk$matchingChunk;
ol.geom.flat.textpath = {};
ol.geom.flat.textpath.drawTextOnPath = _ol_geom_flat_textpath$drawTextOnPath;
ol.geom.flat.topology = {};
ol.geom.flat.topology.lineStringIsClosed = _ol_geom_flat_topology$lineStringIsClosed;
ol.geom.flat.transform = {};
ol.geom.flat.transform.rotate = _ol_geom_flat_transform$rotate;
ol.geom.flat.transform.scale = _ol_geom_flat_transform$scale;
ol.geom.flat.transform.transform2D = _ol_geom_flat_transform$transform2D;
ol.geom.flat.transform.translate = _ol_geom_flat_transform$translate;
ol.has = {};
ol.has.CREATE_IMAGE_BITMAP = _ol_has$CREATE_IMAGE_BITMAP;
ol.has.DEVICE_PIXEL_RATIO = _ol_has$DEVICE_PIXEL_RATIO;
ol.has.FIREFOX = _ol_has$FIREFOX;
ol.has.IMAGE_DECODE = _ol_has$IMAGE_DECODE;
ol.has.MAC = _ol_has$MAC;
ol.has.PASSIVE_EVENT_LISTENERS = _ol_has$PASSIVE_EVENT_LISTENERS;
ol.has.SAFARI = _ol_has$SAFARI;
ol.has.SAFARI_BUG_237906 = _ol_has$SAFARI_BUG_237906;
ol.has.WEBKIT = _ol_has$WEBKIT;
ol.has.WORKER_OFFSCREEN_CANVAS = _ol_has$WORKER_OFFSCREEN_CANVAS;
ol.interaction = {};
ol.interaction.DblClickDragZoom = $ol$interaction$DblClickDragZoom;
ol.interaction.DoubleClickZoom = $ol$interaction$DoubleClickZoom;
ol.interaction.DragAndDrop = $ol$interaction$DragAndDrop;
ol.interaction.DragAndDrop.DragAndDropEvent = _ol_interaction_DragAndDrop$DragAndDropEvent;
ol.interaction.DragBox = $ol$interaction$DragBox;
ol.interaction.DragBox.DragBoxEvent = _ol_interaction_DragBox$DragBoxEvent;
ol.interaction.DragPan = $ol$interaction$DragPan;
ol.interaction.DragRotate = $ol$interaction$DragRotate;
ol.interaction.DragRotateAndZoom = $ol$interaction$DragRotateAndZoom;
ol.interaction.DragZoom = $ol$interaction$DragZoom;
ol.interaction.Draw = $ol$interaction$Draw;
ol.interaction.Draw.DrawEvent = _ol_interaction_Draw$DrawEvent;
ol.interaction.Draw.createBox = _ol_interaction_Draw$createBox;
ol.interaction.Draw.createRegularPolygon = _ol_interaction_Draw$createRegularPolygon;
ol.interaction.Extent = $ol$interaction$Extent;
ol.interaction.Extent.ExtentEvent = _ol_interaction_Extent$ExtentEvent;
ol.interaction.Interaction = $ol$interaction$Interaction;
ol.interaction.Interaction.pan = _ol_interaction_Interaction$pan;
ol.interaction.Interaction.zoomByDelta = _ol_interaction_Interaction$zoomByDelta;
ol.interaction.KeyboardPan = $ol$interaction$KeyboardPan;
ol.interaction.KeyboardZoom = $ol$interaction$KeyboardZoom;
ol.interaction.Link = $ol$interaction$Link;
ol.interaction.Modify = $ol$interaction$Modify;
ol.interaction.Modify.ModifyEvent = _ol_interaction_Modify$ModifyEvent;
ol.interaction.MouseWheelZoom = $ol$interaction$MouseWheelZoom;
ol.interaction.PinchRotate = $ol$interaction$PinchRotate;
ol.interaction.PinchZoom = $ol$interaction$PinchZoom;
ol.interaction.Pointer = $ol$interaction$Pointer;
ol.interaction.Pointer.centroid = _ol_interaction_Pointer$centroid;
ol.interaction.Select = $ol$interaction$Select;
ol.interaction.Select.SelectEvent = _ol_interaction_Select$SelectEvent;
ol.interaction.Snap = $ol$interaction$Snap;
ol.interaction.Translate = $ol$interaction$Translate;
ol.interaction.Translate.TranslateEvent = _ol_interaction_Translate$TranslateEvent;
ol.interaction.defaults = {};
ol.interaction.defaults.defaults = _ol_interaction_defaults$defaults;
ol.layer = {};
ol.layer.Base = $ol$layer$Base;
ol.layer.BaseImage = $ol$layer$BaseImage;
ol.layer.BaseTile = $ol$layer$BaseTile;
ol.layer.BaseVector = $ol$layer$BaseVector;
ol.layer.Graticule = $ol$layer$Graticule;
ol.layer.Group = $ol$layer$Group;
ol.layer.Group.GroupEvent = _ol_layer_Group$GroupEvent;
ol.layer.Heatmap = $ol$layer$Heatmap;
ol.layer.Image = $ol$layer$Image;
ol.layer.Layer = $ol$layer$Layer;
ol.layer.Layer.inView = _ol_layer_Layer$inView;
ol.layer.Tile = $ol$layer$Tile;
ol.layer.Vector = $ol$layer$Vector;
ol.layer.VectorImage = $ol$layer$VectorImage;
ol.layer.VectorTile = $ol$layer$VectorTile;
ol.layer.WebGLPoints = $ol$layer$WebGLPoints;
ol.layer.WebGLTile = $ol$layer$WebGLTile;
ol.loadingstrategy = {};
ol.loadingstrategy.all = _ol_loadingstrategy$all;
ol.loadingstrategy.bbox = _ol_loadingstrategy$bbox;
ol.loadingstrategy.tile = _ol_loadingstrategy$tile;
ol.math = {};
ol.math.ceil = _ol_math$ceil;
ol.math.clamp = _ol_math$clamp;
ol.math.floor = _ol_math$floor;
ol.math.lerp = _ol_math$lerp;
ol.math.modulo = _ol_math$modulo;
ol.math.round = _ol_math$round;
ol.math.solveLinearSystem = _ol_math$solveLinearSystem;
ol.math.squaredDistance = _ol_math$squaredDistance;
ol.math.squaredSegmentDistance = _ol_math$squaredSegmentDistance;
ol.math.toDegrees = _ol_math$toDegrees;
ol.math.toFixed = _ol_math$toFixed;
ol.math.toRadians = _ol_math$toRadians;
ol.net = {};
ol.net.ClientError = _ol_net$ClientError;
ol.net.ResponseError = _ol_net$ResponseError;
ol.net.getJSON = _ol_net$getJSON;
ol.net.jsonp = _ol_net$jsonp;
ol.net.overrideXHR = _ol_net$overrideXHR;
ol.net.resolveUrl = _ol_net$resolveUrl;
ol.net.restoreXHR = _ol_net$restoreXHR;
ol.obj = {};
ol.obj.clear = _ol_obj$clear;
ol.obj.isEmpty = _ol_obj$isEmpty;
ol.proj = {};
ol.proj.Projection = $ol$proj$Projection;
ol.proj.Units = {};
ol.proj.Units.METERS_PER_UNIT = _ol_proj_Units$METERS_PER_UNIT;
ol.proj.Units.fromCode = _ol_proj_Units$fromCode;
ol.proj.addCommon = _ol_proj$addCommon;
ol.proj.addCoordinateTransforms = _ol_proj$addCoordinateTransforms;
ol.proj.addEquivalentProjections = _ol_proj$addEquivalentProjections;
ol.proj.addEquivalentTransforms = _ol_proj$addEquivalentTransforms;
ol.proj.addProjection = _ol_proj$addProjection;
ol.proj.addProjections = _ol_proj$addProjections;
ol.proj.clearAllProjections = _ol_proj$clearAllProjections;
ol.proj.clearUserProjection = _ol_proj$clearUserProjection;
ol.proj.cloneTransform = _ol_proj$cloneTransform;
ol.proj.createProjection = _ol_proj$createProjection;
ol.proj.createSafeCoordinateTransform = _ol_proj$createSafeCoordinateTransform;
ol.proj.createTransformFromCoordinateTransform = _ol_proj$createTransformFromCoordinateTransform;
ol.proj.disableCoordinateWarning = _ol_proj$disableCoordinateWarning;
ol.proj.epsg3857 = {};
ol.proj.epsg3857.EXTENT = _ol_proj_epsg3857$EXTENT;
ol.proj.epsg3857.HALF_SIZE = _ol_proj_epsg3857$HALF_SIZE;
ol.proj.epsg3857.MAX_SAFE_Y = _ol_proj_epsg3857$MAX_SAFE_Y;
ol.proj.epsg3857.PROJECTIONS = _ol_proj_epsg3857$PROJECTIONS;
ol.proj.epsg3857.RADIUS = _ol_proj_epsg3857$RADIUS;
ol.proj.epsg3857.WORLD_EXTENT = _ol_proj_epsg3857$WORLD_EXTENT;
ol.proj.epsg3857.fromEPSG4326 = _ol_proj_epsg3857$fromEPSG4326;
ol.proj.epsg3857.toEPSG4326 = _ol_proj_epsg3857$toEPSG4326;
ol.proj.epsg4326 = {};
ol.proj.epsg4326.EXTENT = _ol_proj_epsg4326$EXTENT;
ol.proj.epsg4326.METERS_PER_UNIT = _ol_proj_epsg4326$METERS_PER_UNIT;
ol.proj.epsg4326.PROJECTIONS = _ol_proj_epsg4326$PROJECTIONS;
ol.proj.epsg4326.RADIUS = _ol_proj_epsg4326$RADIUS;
ol.proj.equivalent = _ol_proj$equivalent;
ol.proj.fromLonLat = _ol_proj$fromLonLat;
ol.proj.fromUserCoordinate = _ol_proj$fromUserCoordinate;
ol.proj.fromUserExtent = _ol_proj$fromUserExtent;
ol.proj.fromUserResolution = _ol_proj$fromUserResolution;
ol.proj.get = _ol_proj$get;
ol.proj.getPointResolution = _ol_proj$getPointResolution;
ol.proj.getTransform = _ol_proj$getTransform;
ol.proj.getTransformFromProjections = _ol_proj$getTransformFromProjections;
ol.proj.getUserProjection = _ol_proj$getUserProjection;
ol.proj.identityTransform = _ol_proj$identityTransform;
ol.proj.proj4 = {};
ol.proj.proj4.epsgLookupMapTiler = _ol_proj_proj4$epsgLookupMapTiler;
ol.proj.proj4.fromEPSGCode = _ol_proj_proj4$fromEPSGCode;
ol.proj.proj4.getEPSGLookup = _ol_proj_proj4$getEPSGLookup;
ol.proj.proj4.isRegistered = _ol_proj_proj4$isRegistered;
ol.proj.proj4.register = _ol_proj_proj4$register;
ol.proj.proj4.setEPSGLookup = _ol_proj_proj4$setEPSGLookup;
ol.proj.proj4.unregister = _ol_proj_proj4$unregister;
ol.proj.projections = {};
ol.proj.projections.add = _ol_proj_projections$add;
ol.proj.projections.clear = _ol_proj_projections$clear;
ol.proj.projections.get = _ol_proj_projections$get;
ol.proj.setUserProjection = _ol_proj$setUserProjection;
ol.proj.toLonLat = _ol_proj$toLonLat;
ol.proj.toUserCoordinate = _ol_proj$toUserCoordinate;
ol.proj.toUserExtent = _ol_proj$toUserExtent;
ol.proj.toUserResolution = _ol_proj$toUserResolution;
ol.proj.transform = _ol_proj$transform;
ol.proj.transformExtent = _ol_proj$transformExtent;
ol.proj.transformWithProjections = _ol_proj$transformWithProjections;
ol.proj.transforms = {};
ol.proj.transforms.add = _ol_proj_transforms$add;
ol.proj.transforms.clear = _ol_proj_transforms$clear;
ol.proj.transforms.get = _ol_proj_transforms$get;
ol.proj.transforms.remove = _ol_proj_transforms$remove;
ol.proj.useGeographic = _ol_proj$useGeographic;
ol.render = {};
ol.render.Box = $ol$render$Box;
ol.render.Event = $ol$render$Event;
ol.render.Feature = $ol$render$Feature;
ol.render.Feature.toFeature = _ol_render_Feature$toFeature;
ol.render.Feature.toGeometry = _ol_render_Feature$toGeometry;
ol.render.VectorContext = $ol$render$VectorContext;
ol.render.canvas = {};
ol.render.canvas.Builder = $ol$render$canvas$Builder;
ol.render.canvas.BuilderGroup = $ol$render$canvas$BuilderGroup;
ol.render.canvas.Executor = $ol$render$canvas$Executor;
ol.render.canvas.ExecutorGroup = $ol$render$canvas$ExecutorGroup;
ol.render.canvas.ExecutorGroup.ALL = _ol_render_canvas_ExecutorGroup$ALL;
ol.render.canvas.ExecutorGroup.DECLUTTER = _ol_render_canvas_ExecutorGroup$DECLUTTER;
ol.render.canvas.ExecutorGroup.NON_DECLUTTER = _ol_render_canvas_ExecutorGroup$NON_DECLUTTER;
ol.render.canvas.ExecutorGroup.getPixelIndexArray = _ol_render_canvas_ExecutorGroup$getPixelIndexArray;
ol.render.canvas.ImageBuilder = $ol$render$canvas$ImageBuilder;
ol.render.canvas.Immediate = $ol$render$canvas$Immediate;
ol.render.canvas.Instruction = {};
ol.render.canvas.Instruction.beginPathInstruction = _ol_render_canvas_Instruction$beginPathInstruction;
ol.render.canvas.Instruction.closePathInstruction = _ol_render_canvas_Instruction$closePathInstruction;
ol.render.canvas.Instruction.fillInstruction = _ol_render_canvas_Instruction$fillInstruction;
ol.render.canvas.Instruction.strokeInstruction = _ol_render_canvas_Instruction$strokeInstruction;
ol.render.canvas.LineStringBuilder = $ol$render$canvas$LineStringBuilder;
ol.render.canvas.PolygonBuilder = $ol$render$canvas$PolygonBuilder;
ol.render.canvas.TextBuilder = $ol$render$canvas$TextBuilder;
ol.render.canvas.TextBuilder.TEXT_ALIGN = _ol_render_canvas_TextBuilder$TEXT_ALIGN;
ol.render.canvas.ZIndexContext = $ol$render$canvas$ZIndexContext;
ol.render.canvas.checkedFonts = _ol_render_canvas$checkedFonts;
ol.render.canvas.defaultFillStyle = _ol_render_canvas$defaultFillStyle;
ol.render.canvas.defaultFont = _ol_render_canvas$defaultFont;
ol.render.canvas.defaultLineCap = _ol_render_canvas$defaultLineCap;
ol.render.canvas.defaultLineDash = _ol_render_canvas$defaultLineDash;
ol.render.canvas.defaultLineDashOffset = _ol_render_canvas$defaultLineDashOffset;
ol.render.canvas.defaultLineJoin = _ol_render_canvas$defaultLineJoin;
ol.render.canvas.defaultLineWidth = _ol_render_canvas$defaultLineWidth;
ol.render.canvas.defaultMiterLimit = _ol_render_canvas$defaultMiterLimit;
ol.render.canvas.defaultPadding = _ol_render_canvas$defaultPadding;
ol.render.canvas.defaultStrokeStyle = _ol_render_canvas$defaultStrokeStyle;
ol.render.canvas.defaultTextAlign = _ol_render_canvas$defaultTextAlign;
ol.render.canvas.defaultTextBaseline = _ol_render_canvas$defaultTextBaseline;
ol.render.canvas.drawImageOrLabel = _ol_render_canvas$drawImageOrLabel;
ol.render.canvas.getTextDimensions = _ol_render_canvas$getTextDimensions;
ol.render.canvas.hitdetect = {};
ol.render.canvas.hitdetect.HIT_DETECT_RESOLUTION = _ol_render_canvas_hitdetect$HIT_DETECT_RESOLUTION;
ol.render.canvas.hitdetect.createHitDetectionImageData = _ol_render_canvas_hitdetect$createHitDetectionImageData;
ol.render.canvas.hitdetect.hitDetect = _ol_render_canvas_hitdetect$hitDetect;
ol.render.canvas.measureAndCacheTextWidth = _ol_render_canvas$measureAndCacheTextWidth;
ol.render.canvas.measureTextHeight = _ol_render_canvas$measureTextHeight;
ol.render.canvas.measureTextWidth = _ol_render_canvas$measureTextWidth;
ol.render.canvas.registerFont = _ol_render_canvas$registerFont;
ol.render.canvas.rotateAtOffset = _ol_render_canvas$rotateAtOffset;
ol.render.canvas.style = {};
ol.render.canvas.style.buildRuleSet = _ol_render_canvas_style$buildRuleSet;
ol.render.canvas.style.buildStyle = _ol_render_canvas_style$buildStyle;
ol.render.canvas.style.flatStylesToStyleFunction = _ol_render_canvas_style$flatStylesToStyleFunction;
ol.render.canvas.style.rulesToStyleFunction = _ol_render_canvas_style$rulesToStyleFunction;
ol.render.canvas.textHeights = _ol_render_canvas$textHeights;
ol.render.getRenderPixel = _ol_render$getRenderPixel;
ol.render.getVectorContext = _ol_render$getVectorContext;
ol.render.toContext = _ol_render$toContext;
ol.render.webgl = {};
ol.render.webgl.MixedGeometryBatch = $ol$render$webgl$MixedGeometryBatch;
ol.render.webgl.VectorStyleRenderer = $ol$render$webgl$VectorStyleRenderer;
ol.render.webgl.renderinstructions = {};
ol.render.webgl.renderinstructions.generateLineStringRenderInstructions = _ol_render_webgl_renderinstructions$generateLineStringRenderInstructions;
ol.render.webgl.renderinstructions.generatePointRenderInstructions = _ol_render_webgl_renderinstructions$generatePointRenderInstructions;
ol.render.webgl.renderinstructions.generatePolygonRenderInstructions = _ol_render_webgl_renderinstructions$generatePolygonRenderInstructions;
ol.render.webgl.renderinstructions.getCustomAttributesSize = _ol_render_webgl_renderinstructions$getCustomAttributesSize;
ol.render.webgl.utils = {};
ol.render.webgl.utils.LINESTRING_ANGLE_COSINE_CUTOFF = _ol_render_webgl_utils$LINESTRING_ANGLE_COSINE_CUTOFF;
ol.render.webgl.utils.colorDecodeId = _ol_render_webgl_utils$colorDecodeId;
ol.render.webgl.utils.colorEncodeId = _ol_render_webgl_utils$colorEncodeId;
ol.render.webgl.utils.getBlankImageData = _ol_render_webgl_utils$getBlankImageData;
ol.render.webgl.utils.writeLineSegmentToBuffers = _ol_render_webgl_utils$writeLineSegmentToBuffers;
ol.render.webgl.utils.writePointFeatureToBuffers = _ol_render_webgl_utils$writePointFeatureToBuffers;
ol.render.webgl.utils.writePolygonTrianglesToBuffers = _ol_render_webgl_utils$writePolygonTrianglesToBuffers;
ol.renderer = {};
ol.renderer.Composite = $ol$renderer$Composite;
ol.renderer.Layer = $ol$renderer$Layer;
ol.renderer.Map = $ol$renderer$Map;
ol.renderer.canvas = {};
ol.renderer.canvas.ImageLayer = $ol$renderer$canvas$ImageLayer;
ol.renderer.canvas.Layer = $ol$renderer$canvas$Layer;
ol.renderer.canvas.Layer.canvasPool = _ol_renderer_canvas_Layer$canvasPool;
ol.renderer.canvas.TileLayer = $ol$renderer$canvas$TileLayer;
ol.renderer.canvas.VectorImageLayer = $ol$renderer$canvas$VectorImageLayer;
ol.renderer.canvas.VectorLayer = $ol$renderer$canvas$VectorLayer;
ol.renderer.canvas.VectorTileLayer = $ol$renderer$canvas$VectorTileLayer;
ol.renderer.vector = {};
ol.renderer.vector.defaultOrder = _ol_renderer_vector$defaultOrder;
ol.renderer.vector.getSquaredTolerance = _ol_renderer_vector$getSquaredTolerance;
ol.renderer.vector.getTolerance = _ol_renderer_vector$getTolerance;
ol.renderer.vector.renderFeature = _ol_renderer_vector$renderFeature;
ol.renderer.webgl = {};
ol.renderer.webgl.Layer = $ol$renderer$webgl$Layer;
ol.renderer.webgl.PointsLayer = $ol$renderer$webgl$PointsLayer;
ol.renderer.webgl.TileLayer = $ol$renderer$webgl$TileLayer;
ol.renderer.webgl.TileLayer.Attributes = _ol_renderer_webgl_TileLayer$Attributes;
ol.renderer.webgl.TileLayer.Uniforms = _ol_renderer_webgl_TileLayer$Uniforms;
ol.renderer.webgl.TileLayerBase = $ol$renderer$webgl$TileLayerBase;
ol.renderer.webgl.TileLayerBase.Uniforms = _ol_renderer_webgl_TileLayerBase$Uniforms;
ol.renderer.webgl.TileLayerBase.getCacheKey = _ol_renderer_webgl_TileLayerBase$getCacheKey;
ol.renderer.webgl.TileLayerBase.newTileRepresentationLookup = _ol_renderer_webgl_TileLayerBase$newTileRepresentationLookup;
ol.renderer.webgl.VectorLayer = $ol$renderer$webgl$VectorLayer;
ol.renderer.webgl.VectorLayer.Uniforms = _ol_renderer_webgl_VectorLayer$Uniforms;
ol.renderer.webgl.VectorTileLayer = $ol$renderer$webgl$VectorTileLayer;
ol.renderer.webgl.VectorTileLayer.Attributes = _ol_renderer_webgl_VectorTileLayer$Attributes;
ol.renderer.webgl.VectorTileLayer.Uniforms = _ol_renderer_webgl_VectorTileLayer$Uniforms;
ol.reproj = {};
ol.reproj.DataTile = $ol$reproj$DataTile;
ol.reproj.Image = $ol$reproj$Image;
ol.reproj.Tile = $ol$reproj$Tile;
ol.reproj.Triangulation = $ol$reproj$Triangulation;
ol.reproj.calculateSourceExtentResolution = _ol_reproj$calculateSourceExtentResolution;
ol.reproj.calculateSourceResolution = _ol_reproj$calculateSourceResolution;
ol.reproj.canvasPool = _ol_reproj$canvasPool;
ol.reproj.common = {};
ol.reproj.common.ERROR_THRESHOLD = _ol_reproj_common$ERROR_THRESHOLD;
ol.reproj.render = _ol_reproj$render;
ol.resolution = {};
ol.resolution.fromResolutionLike = _ol_resolution$fromResolutionLike;
ol.resolutionconstraint = {};
ol.resolutionconstraint.createMinMaxResolution = _ol_resolutionconstraint$createMinMaxResolution;
ol.resolutionconstraint.createSnapToPower = _ol_resolutionconstraint$createSnapToPower;
ol.resolutionconstraint.createSnapToResolutions = _ol_resolutionconstraint$createSnapToResolutions;
ol.rotationconstraint = {};
ol.rotationconstraint.createSnapToN = _ol_rotationconstraint$createSnapToN;
ol.rotationconstraint.createSnapToZero = _ol_rotationconstraint$createSnapToZero;
ol.rotationconstraint.disable = _ol_rotationconstraint$disable;
ol.rotationconstraint.none = _ol_rotationconstraint$none;
ol.size = {};
ol.size.buffer = _ol_size$buffer;
ol.size.hasArea = _ol_size$hasArea;
ol.size.scale = _ol_size$scale;
ol.size.toSize = _ol_size$toSize;
ol.source = {};
ol.source.BingMaps = $ol$source$BingMaps;
ol.source.BingMaps.quadKey = _ol_source_BingMaps$quadKey;
ol.source.CartoDB = $ol$source$CartoDB;
ol.source.Cluster = $ol$source$Cluster;
ol.source.DataTile = $ol$source$DataTile;
ol.source.GeoTIFF = $ol$source$GeoTIFF;
ol.source.Google = $ol$source$Google;
ol.source.IIIF = $ol$source$IIIF;
ol.source.Image = $ol$source$Image;
ol.source.Image.ImageSourceEvent = _ol_source_Image$ImageSourceEvent;
ol.source.Image.defaultImageLoadFunction = _ol_source_Image$defaultImageLoadFunction;
ol.source.Image.getRequestExtent = _ol_source_Image$getRequestExtent;
ol.source.ImageArcGISRest = $ol$source$ImageArcGISRest;
ol.source.ImageCanvas = $ol$source$ImageCanvas;
ol.source.ImageMapGuide = $ol$source$ImageMapGuide;
ol.source.ImageStatic = $ol$source$ImageStatic;
ol.source.ImageWMS = $ol$source$ImageWMS;
ol.source.OGCMapTile = $ol$source$OGCMapTile;
ol.source.OGCVectorTile = $ol$source$OGCVectorTile;
ol.source.OSM = $ol$source$OSM;
ol.source.OSM.ATTRIBUTION = _ol_source_OSM$ATTRIBUTION;
ol.source.Raster = $ol$source$Raster;
ol.source.Raster.Processor = _ol_source_Raster$Processor;
ol.source.Raster.RasterSourceEvent = _ol_source_Raster$RasterSourceEvent;
ol.source.Raster.newImageData = _ol_source_Raster$newImageData;
ol.source.Source = $ol$source$Source;
ol.source.StadiaMaps = $ol$source$StadiaMaps;
ol.source.Tile = $ol$source$Tile;
ol.source.Tile.TileSourceEvent = _ol_source_Tile$TileSourceEvent;
ol.source.TileArcGISRest = $ol$source$TileArcGISRest;
ol.source.TileDebug = $ol$source$TileDebug;
ol.source.TileImage = $ol$source$TileImage;
ol.source.TileJSON = $ol$source$TileJSON;
ol.source.TileWMS = $ol$source$TileWMS;
ol.source.UTFGrid = $ol$source$UTFGrid;
ol.source.UTFGrid.CustomTile = _ol_source_UTFGrid$CustomTile;
ol.source.UrlTile = $ol$source$UrlTile;
ol.source.Vector = $ol$source$Vector;
ol.source.Vector.VectorSourceEvent = _ol_source_Vector$VectorSourceEvent;
ol.source.VectorTile = $ol$source$VectorTile;
ol.source.VectorTile.defaultLoadFunction = _ol_source_VectorTile$defaultLoadFunction;
ol.source.WMTS = $ol$source$WMTS;
ol.source.WMTS.optionsFromCapabilities = _ol_source_WMTS$optionsFromCapabilities;
ol.source.XYZ = $ol$source$XYZ;
ol.source.Zoomify = $ol$source$Zoomify;
ol.source.Zoomify.CustomTile = _ol_source_Zoomify$CustomTile;
ol.source.arcgisRest = {};
ol.source.arcgisRest.createLoader = _ol_source_arcgisRest$createLoader;
ol.source.arcgisRest.getRequestUrl = _ol_source_arcgisRest$getRequestUrl;
ol.source.common = {};
ol.source.common.DECIMALS = _ol_source_common$DECIMALS;
ol.source.common.DEFAULT_WMS_VERSION = _ol_source_common$DEFAULT_WMS_VERSION;
ol.source.mapguide = {};
ol.source.mapguide.createLoader = _ol_source_mapguide$createLoader;
ol.source.ogcTileUtil = {};
ol.source.ogcTileUtil.appendCollectionsQueryParam = _ol_source_ogcTileUtil$appendCollectionsQueryParam;
ol.source.ogcTileUtil.getMapTileUrlTemplate = _ol_source_ogcTileUtil$getMapTileUrlTemplate;
ol.source.ogcTileUtil.getTileSetInfo = _ol_source_ogcTileUtil$getTileSetInfo;
ol.source.ogcTileUtil.getVectorTileUrlTemplate = _ol_source_ogcTileUtil$getVectorTileUrlTemplate;
ol.source.sourcesFromTileGrid = _ol_source$sourcesFromTileGrid;
ol.source.static = {};
ol.source.static.createLoader = _ol_source_static$createLoader;
ol.source.wms = {};
ol.source.wms.DEFAULT_VERSION = _ol_source_wms$DEFAULT_VERSION;
ol.source.wms.createLoader = _ol_source_wms$createLoader;
ol.source.wms.getFeatureInfoUrl = _ol_source_wms$getFeatureInfoUrl;
ol.source.wms.getImageSrc = _ol_source_wms$getImageSrc;
ol.source.wms.getLegendUrl = _ol_source_wms$getLegendUrl;
ol.source.wms.getRequestParams = _ol_source_wms$getRequestParams;
ol.source.wms.getRequestUrl = _ol_source_wms$getRequestUrl;
ol.sphere = {};
ol.sphere.DEFAULT_RADIUS = _ol_sphere$DEFAULT_RADIUS;
ol.sphere.getArea = _ol_sphere$getArea;
ol.sphere.getDistance = _ol_sphere$getDistance;
ol.sphere.getLength = _ol_sphere$getLength;
ol.sphere.offset = _ol_sphere$offset;
ol.string = {};
ol.string.compareVersions = _ol_string$compareVersions;
ol.string.padNumber = _ol_string$padNumber;
ol.structs = {};
ol.structs.LRUCache = $ol$structs$LRUCache;
ol.structs.LinkedList = $ol$structs$LinkedList;
ol.structs.PriorityQueue = $ol$structs$PriorityQueue;
ol.structs.PriorityQueue.DROP = _ol_structs_PriorityQueue$DROP;
ol.structs.RBush = $ol$structs$RBush;
ol.style = {};
ol.style.Circle = $ol$style$Circle;
ol.style.Fill = $ol$style$Fill;
ol.style.Icon = $ol$style$Icon;
ol.style.IconImage = $ol$style$IconImage;
ol.style.IconImage.get = _ol_style_IconImage$get;
ol.style.IconImageCache = $ol$style$IconImageCache;
ol.style.IconImageCache.getCacheKey = _ol_style_IconImageCache$getCacheKey;
ol.style.IconImageCache.shared = _ol_style_IconImageCache$shared;
ol.style.Image = $ol$style$Image;
ol.style.RegularShape = $ol$style$RegularShape;
ol.style.Stroke = $ol$style$Stroke;
ol.style.Style = $ol$style$Style;
ol.style.Style.createDefaultStyle = _ol_style_Style$createDefaultStyle;
ol.style.Style.createEditingStyle = _ol_style_Style$createEditingStyle;
ol.style.Style.toFunction = _ol_style_Style$toFunction;
ol.style.Text = $ol$style$Text;
ol.style.flat = {};
ol.style.flat.createDefaultStyle = _ol_style_flat$createDefaultStyle;
ol.tilecoord = {};
ol.tilecoord.createOrUpdate = _ol_tilecoord$createOrUpdate;
ol.tilecoord.fromKey = _ol_tilecoord$fromKey;
ol.tilecoord.getCacheKeyForTileKey = _ol_tilecoord$getCacheKeyForTileKey;
ol.tilecoord.getKey = _ol_tilecoord$getKey;
ol.tilecoord.getKeyZXY = _ol_tilecoord$getKeyZXY;
ol.tilecoord.hash = _ol_tilecoord$hash;
ol.tilecoord.withinExtentAndZ = _ol_tilecoord$withinExtentAndZ;
ol.tilegrid = {};
ol.tilegrid.TileGrid = $ol$tilegrid$TileGrid;
ol.tilegrid.WMTS = $ol$tilegrid$WMTS;
ol.tilegrid.WMTS.createFromCapabilitiesMatrixSet = _ol_tilegrid_WMTS$createFromCapabilitiesMatrixSet;
ol.tilegrid.common = {};
ol.tilegrid.common.DEFAULT_MAX_ZOOM = _ol_tilegrid_common$DEFAULT_MAX_ZOOM;
ol.tilegrid.common.DEFAULT_TILE_SIZE = _ol_tilegrid_common$DEFAULT_TILE_SIZE;
ol.tilegrid.createForExtent = _ol_tilegrid$createForExtent;
ol.tilegrid.createForProjection = _ol_tilegrid$createForProjection;
ol.tilegrid.createXYZ = _ol_tilegrid$createXYZ;
ol.tilegrid.extentFromProjection = _ol_tilegrid$extentFromProjection;
ol.tilegrid.getForProjection = _ol_tilegrid$getForProjection;
ol.tilegrid.wrapX = _ol_tilegrid$wrapX;
ol.tileurlfunction = {};
ol.tileurlfunction.createFromTemplate = _ol_tileurlfunction$createFromTemplate;
ol.tileurlfunction.createFromTemplates = _ol_tileurlfunction$createFromTemplates;
ol.tileurlfunction.createFromTileUrlFunctions = _ol_tileurlfunction$createFromTileUrlFunctions;
ol.tileurlfunction.expandUrl = _ol_tileurlfunction$expandUrl;
ol.tileurlfunction.nullTileUrlFunction = _ol_tileurlfunction$nullTileUrlFunction;
ol.transform = {};
ol.transform.apply = _ol_transform$apply;
ol.transform.compose = _ol_transform$compose;
ol.transform.composeCssTransform = _ol_transform$composeCssTransform;
ol.transform.create = _ol_transform$create;
ol.transform.determinant = _ol_transform$determinant;
ol.transform.invert = _ol_transform$invert;
ol.transform.makeInverse = _ol_transform$makeInverse;
ol.transform.makeScale = _ol_transform$makeScale;
ol.transform.multiply = _ol_transform$multiply;
ol.transform.reset = _ol_transform$reset;
ol.transform.rotate = _ol_transform$rotate;
ol.transform.scale = _ol_transform$scale;
ol.transform.set = _ol_transform$set;
ol.transform.setFromArray = _ol_transform$setFromArray;
ol.transform.toString = _ol_transform$toString;
ol.transform.translate = _ol_transform$translate;
ol.uri = {};
ol.uri.appendParams = _ol_uri$appendParams;
ol.util = {};
ol.util.VERSION = _ol_util$VERSION;
ol.util.abstract = _ol_util$abstract;
ol.util.getUid = _ol_util$getUid;
ol.vec = {};
ol.vec.mat4 = {};
ol.vec.mat4.create = _ol_vec_mat4$create;
ol.vec.mat4.fromTransform = _ol_vec_mat4$fromTransform;
ol.webgl = {};
ol.webgl.ARRAY_BUFFER = _ol_webgl$ARRAY_BUFFER;
ol.webgl.BaseTileRepresentation = $ol$webgl$BaseTileRepresentation;
ol.webgl.Buffer = $ol$webgl$Buffer;
ol.webgl.Buffer.getArrayClassForType = _ol_webgl_Buffer$getArrayClassForType;
ol.webgl.DYNAMIC_DRAW = _ol_webgl$DYNAMIC_DRAW;
ol.webgl.ELEMENT_ARRAY_BUFFER = _ol_webgl$ELEMENT_ARRAY_BUFFER;
ol.webgl.FLOAT = _ol_webgl$FLOAT;
ol.webgl.Helper = $ol$webgl$Helper;
ol.webgl.Helper.computeAttributesStride = _ol_webgl_Helper$computeAttributesStride;
ol.webgl.PaletteTexture = $ol$webgl$PaletteTexture;
ol.webgl.PostProcessingPass = $ol$webgl$PostProcessingPass;
ol.webgl.RenderTarget = $ol$webgl$RenderTarget;
ol.webgl.STATIC_DRAW = _ol_webgl$STATIC_DRAW;
ol.webgl.STREAM_DRAW = _ol_webgl$STREAM_DRAW;
ol.webgl.ShaderBuilder = {};
ol.webgl.ShaderBuilder.COMMON_HEADER = _ol_webgl_ShaderBuilder$COMMON_HEADER;
ol.webgl.ShaderBuilder.ShaderBuilder = _ol_webgl_ShaderBuilder$ShaderBuilder;
ol.webgl.TileGeometry = $ol$webgl$TileGeometry;
ol.webgl.TileTexture = $ol$webgl$TileTexture;
ol.webgl.UNSIGNED_BYTE = _ol_webgl$UNSIGNED_BYTE;
ol.webgl.UNSIGNED_INT = _ol_webgl$UNSIGNED_INT;
ol.webgl.UNSIGNED_SHORT = _ol_webgl$UNSIGNED_SHORT;
ol.webgl.getContext = _ol_webgl$getContext;
ol.webgl.getSupportedExtensions = _ol_webgl$getSupportedExtensions;
ol.webgl.styleparser = {};
ol.webgl.styleparser.computeHash = _ol_webgl_styleparser$computeHash;
ol.webgl.styleparser.expressionToGlsl = _ol_webgl_styleparser$expressionToGlsl;
ol.webgl.styleparser.packColor = _ol_webgl_styleparser$packColor;
ol.webgl.styleparser.parseLiteralStyle = _ol_webgl_styleparser$parseLiteralStyle;
ol.xml = {};
ol.xml.OBJECT_PROPERTY_NODE_FACTORY = _ol_xml$OBJECT_PROPERTY_NODE_FACTORY;
ol.xml.XML_SCHEMA_INSTANCE_URI = _ol_xml$XML_SCHEMA_INSTANCE_URI;
ol.xml.createElementNS = _ol_xml$createElementNS;
ol.xml.getAllTextContent = _ol_xml$getAllTextContent;
ol.xml.getAllTextContent_ = _ol_xml$getAllTextContent_;
ol.xml.getAttributeNS = _ol_xml$getAttributeNS;
ol.xml.getDocument = _ol_xml$getDocument;
ol.xml.getXMLSerializer = _ol_xml$getXMLSerializer;
ol.xml.isDocument = _ol_xml$isDocument;
ol.xml.makeArrayExtender = _ol_xml$makeArrayExtender;
ol.xml.makeArrayPusher = _ol_xml$makeArrayPusher;
ol.xml.makeArraySerializer = _ol_xml$makeArraySerializer;
ol.xml.makeChildAppender = _ol_xml$makeChildAppender;
ol.xml.makeObjectPropertyPusher = _ol_xml$makeObjectPropertyPusher;
ol.xml.makeObjectPropertySetter = _ol_xml$makeObjectPropertySetter;
ol.xml.makeReplacer = _ol_xml$makeReplacer;
ol.xml.makeSequence = _ol_xml$makeSequence;
ol.xml.makeSimpleNodeFactory = _ol_xml$makeSimpleNodeFactory;
ol.xml.makeStructureNS = _ol_xml$makeStructureNS;
ol.xml.parse = _ol_xml$parse;
ol.xml.parseNode = _ol_xml$parseNode;
ol.xml.pushParseAndPop = _ol_xml$pushParseAndPop;
ol.xml.pushSerializeAndPop = _ol_xml$pushSerializeAndPop;
ol.xml.registerDocument = _ol_xml$registerDocument;
ol.xml.registerXMLSerializer = _ol_xml$registerXMLSerializer;
ol.xml.serialize = _ol_xml$serialize;

export default ol;